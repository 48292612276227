import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Sidebar from "../Design/Sidebar.js";
import { useSelector } from "react-redux";

const ShopEditClient = () => {
  const shop = useSelector((state) => state.shop);

  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const STATUS_OPTION = [
    { value: "vip", label: "VIP" },
    { value: "good", label: "Good" },
    { value: "caution", label: "Caution" },
    { value: "warning", label: "Warning" },
    { value: "banned", label: "Banned" },
    { value: "inactive", label: "Inactive" },
  ];

  const [clientData, setClientData] = useState({
    Prefix: "",
    FirstName: "",
    LastName: "",
    Address: "",
    AddressTwo: "",
    City: "",
    StateCountry: "",
    PostalCode: "",
    Area: "",
    LocalTaxRate: "",
    ClientReferralSource: "",
    ClientSince: null,
    SpecialNotes: "",
    Status: "",
    Status: "good",
    contacts: [],
  });

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/edit-client/${id}/`);
        setClientData({
          ...response.data,
          contacts: response.data.contacts || [],
        });
      } catch (error) {
        console.error("Failed to fetch client data:", error);
      }
    };

    fetchClientData();
  }, [id, apiUrl]);


  const handleChange = async (e) => {
    const { name, value } = e.target;
    setClientData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "PostalCode" && value.trim().length > 0) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: value,
              components: "country:MX",
              key: googleMapsApiKey,
            },
          }
        );

        if (response.data.status === "OK") {
          const addressComponents = response.data.results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const areaComponent = addressComponents.find(
            (component) =>
              component.types.includes("sublocality") ||
              component.types.includes("political")
          );

          setClientData((prevState) => ({
            ...prevState,
            City: cityComponent ? cityComponent.long_name : "",
            Area: areaComponent ? areaComponent.long_name : "",
          }));
        }
      } catch (error) {
        console.error(
          "Error fetching data from Google Maps Geocoding API",
          error
        );
      }
    }
  };

  const handleContactChange = (e, index) => {
    const { name, value } = e.target;
    const updatedContacts = [...clientData.contacts];
    updatedContacts[index] = { ...updatedContacts[index], [name]: value };
    setClientData({ ...clientData, contacts: updatedContacts });

  };

  const addContact = () => {
    setClientData((prevState) => ({
      ...prevState,
      contacts: [
        ...prevState.contacts,
        {
          contact_type: "",
          contact_value: "",
          contact_by: "",
          preferred_contact: "",
          primary: false,
        },
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${apiUrl}api/edit-client/${id}/`,
        clientData
      );
      alert("Client is updated successfuly!");
      window.location.href = `/shop`;
    } catch (error) {
      alert("Check all fields!");
    }
  };

  const handlePrimaryChange = (index) => {
    const updatedContacts = [...clientData.contacts];
    updatedContacts[index].primary = !updatedContacts[index].primary;
    setClientData({ ...clientData, contacts: updatedContacts });
  };

  // Form rendering logic goes here
  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar userId={id} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Edit Client
        </h3>
        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <form onSubmit={handleSubmit} className="container mt-5">
            <div className="row mb-3">
              <div className="col-md-12">
                <label>Client Status (required)</label>
                <select
                  name="Status"
                  value={clientData.Status}
                  onChange={handleChange}
                  className="form-control"
                  required
                >
                  {STATUS_OPTION.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>Prefix (optional)</label>
                <select
                  name="Prefix"
                  value={clientData.Prefix}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                </select>
              </div>
              <div className="col-md-6">
                <label>First Name (required)</label>
                <input
                  type="text"
                  name="FirstName"
                  value={clientData.FirstName}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label>Last Name (required)</label>
                <input
                  type="text"
                  name="LastName"
                  value={clientData.LastName}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label>Address (optional)</label>
                <input
                  type="text"
                  name="Address"
                  value={clientData.Address}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label>Address Two (optional)</label>
                <input
                  type="text"
                  name="AddressTwo"
                  value={clientData.AddressTwo}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label>City (optional)</label>
                <input
                  type="text"
                  name="City"
                  value={clientData.City}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>State/Country (optional)</label>
                <select
                  name="StateCountry"
                  value={clientData.StateCountry}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="Aguascalientes">Aguascalientes</option>
                  <option value="Baja California">Baja California</option>
                  <option value="Baja California Sur">
                    Baja California Sur
                  </option>
                  <option value="Campeche">Campeche</option>
                  <option value="Chiapas">Chiapas</option>
                  <option value="Chihuahua">Chihuahua</option>
                  <option value="Ciudad de México">Ciudad de México</option>
                  <option value="Coahuila">Coahuila</option>
                  <option value="Colima">Colima</option>
                  <option value="Durango">Durango</option>
                  <option value="Guanajuato">Guanajuato</option>
                  <option value="Guerrero">Guerrero</option>
                  <option value="Hidalgo">Hidalgo</option>
                  <option value="Jalisco">Jalisco</option>
                  <option value="México">México</option>
                  <option value="Michoacán">Michoacán</option>
                  <option value="Morelos">Morelos</option>
                  <option value="Nayarit">Nayarit</option>
                  <option value="Nuevo León">Nuevo León</option>
                  <option value="Oaxaca">Oaxaca</option>
                  <option value="Puebla">Puebla</option>
                  <option value="Querétaro">Querétaro</option>
                  <option value="Quintana Roo">Quintana Roo</option>
                  <option value="San Luis Potosí">San Luis Potosí</option>
                  <option value="Sinaloa">Sinaloa</option>
                  <option value="Sonora">Sonora</option>
                  <option value="Tabasco">Tabasco</option>
                  <option value="Tamaulipas">Tamaulipas</option>
                  <option value="Tlaxcala">Tlaxcala</option>
                  <option value="Veracruz">Veracruz</option>
                  <option value="Yucatán">Yucatán</option>
                  <option value="Zacatecas">Zacatecas</option>
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label>Postal Code (optional)</label>
                <input
                  type="text"
                  name="PostalCode"
                  value={clientData.PostalCode}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Area (optional)</label>
                <input
                  type="text"
                  name="Area"
                  value={clientData.Area}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                {/*}    <label>Local Tax Rate (optional)</label>
                <input
                  type="text"
                  name="LocalTaxRate"
                  value={clientData.LocalTaxRate}
                  onChange={handleChange}
                  className="form-control"
      /> */}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label>Client Refferal Source (optional)</label>
                <select
                  name="ClientRefferalSource"
                  value={clientData.ClientRefferalSource}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select a source</option>
                  <option value="Search Results - Google">
                    Search Results - Google
                  </option>
                  <option value="Search Results - Bing">
                    Search Results - Bing
                  </option>
                  <option value="Search Results - Yahoo">
                    Search Results - Yahoo
                  </option>
                  <option value="Social - Facebook">Social - Facebook</option>
                  <option value="Social - Twitter">Social - Twitter</option>
                  <option value="Social - Instagram">Social - Instagram</option>
                  <option value="Referral - Friend or Family">
                    Referral - Friend or Family
                  </option>
                  <option value="Referral - Groomer">Referral - Groomer</option>
                  <option value="Referral - Pet Store">
                    Referral - Pet Store
                  </option>
                  <option value="Referral - Kennel">Referral - Kennel</option>
                  <option value="Referral - Veterinarian">
                    Referral - Veterinarian
                  </option>
                  <option value="Returning Client">Returning Client</option>
                  <option value="Saw Sign - Drive by ">
                    Saw Sign - Drive by{" "}
                  </option>
                  <option value="Advertisement - Online / Digital">
                    Advertisement - Online / Digital
                  </option>
                  <option value="Advertisement - Print">
                    Advertisement - Print
                  </option>
                  <option value="Advertisement - Radio">
                    Advertisement - Radio
                  </option>
                  <option value="Walk In">Walk In</option>
                  <option value="Groupon">Groupon</option>
                  <option value="ValPack">ValPack</option>
                  <option value="Website">Website</option>
                  <option value="Yelp">Yelp</option>
                  <option value="Other Source - Not Listed">
                    Other Source - Not Listed
                  </option>
                </select>
              </div>
              <div className="col-md-6">
                <label>Client Since (optional)</label>
                <input
                  type="date"
                  name="ClientSince"
                  value={clientData.ClientSince}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                {/*}    <label>Local Tax Rate (optional)</label>
                <input
                  type="text"
                  name="LocalTaxRate"
                  value={clientData.LocalTaxRate}
                  onChange={handleChange}
                  className="form-control"
    /> */}
              </div>
              <div className="col-md-6">
                <label>Special Notes (optional)</label>
                <textarea
                  name="SpecialNotes"
                  value={clientData.SpecialNotes}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label></label>
              </div>
              {clientData.contacts.map((contact, index) => (
                <div key={index} className="col-md-4">
                  <label>Contact Type (required)</label>
                  <select
                    name="contact_type"
                    value={contact.contact_type}
                    onChange={(e) => handleContactChange(e, index)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Home">Home</option>
                    <option value="Mobile">Mobile</option>
                    <option value="Email">Email</option>
                    <option value="Work">Work</option>
                  </select>
                  <label>Contact (required)</label>
                  <input
                    type="text"
                    name="contact_value"
                    value={contact.contact_value}
                    onChange={(e) => handleContactChange(e, index)}
                    className="form-control"
                  />
                  {!contact.primary && (
                    <button
                      type="button"
                      onClick={() => handlePrimaryChange(index)}
                      className="btn btn-primary mt-2"
                    >
                      Set as Primary
                    </button>
                  )}
                  <br />
                  {contact.primary && (
                    <p className="text-success mt-2 mb-4">Primary Contact</p>
                  )}
                  <label>Contact By (required)</label>
                  <input
                    type="text"
                    name="contact_by"
                    value={contact.contact_by}
                    onChange={(e) => handleContactChange(e, index)}
                    className="form-control"
                  />
                  <label>Preferred Contact:</label>
                  <select
                    name="preferred_contact"
                    value={contact.preferred_contact}
                    onChange={(e) => handleContactChange(e, index)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Sms">SMS/Text</option>
                    <option value="Phone">Phone</option>
                    <option value="Email">Email</option>
                  </select>
                </div>
              ))}
            </div>

            {/* Button to add new contact */}
            <div className="row mb-3">
              <div className="col-md-12">
                <button
                  type="button"
                  onClick={addContact}
                  className="btn btn-primary"
                >
                  Add Contact Form
                </button>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <button type="submit" className="btn btn-success mr-2">
                  Submit
                </button>
                <Link to="/shop-clients">
                <button type="submit" className="btn btn-success">
                  Cancel
                </button>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopEditClient;
