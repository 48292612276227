import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Form, Button, Table } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";

const CashReconciliation = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [reconciliations, setReconciliations] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [storeName, setStoreName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [form, setForm] = useState({
    store: "",
    date: "",
    opening_balance: 0,
    sales_in_cash: 0,
    expenses_in_cash: 0,
    gratuities: 0, 
  });
  const [latestReconciliation, setLatestReconciliation] = useState({
    is_closed: true,
    id: null,
    date: null,
  });

  const fetchReconciliations = useCallback(
    async (shopId) => {
      if (!shopId) {
        console.log("No store selected, skipping reconciliation fetch.");
        return; // Exit if no store is selected
      }

      try {
        const response = await axios.get(
          `${apiUrl}inventory/reconciliations/?store=${shopId}`
        );
        const results = response.data.results;
        setReconciliations(results);

        // Dynamically set total pages if the API provides this information
        setTotalPages(response.data.total_pages || 1);

        if (results.length > 0) {
          const latest = results[0]; // Assuming the API returns the most recent reconciliation first
          setLatestReconciliation({
            is_closed: latest.is_closed,
            id: latest.id,
            date: latest.date,
          });
        } else {
          setLatestReconciliation({ is_closed: true, id: null, date: null }); // Default if no reconciliation exists
        }
        
      } catch (error) {
        console.error("Error fetching reconciliations:", error);
      }
    },
    [apiUrl]
  );

  useEffect(() => {
    if (shopId) {
      fetchReconciliations(shopId);
    }
  }, [shopId, fetchReconciliations]);

  const fetchStores = useCallback(async () => {
    const response = await axios.get(`${apiUrl}inventory/stores/`);
    setStores(response.data);
  }, [apiUrl]);

  useEffect(() => {
    fetchStores();
  }, [fetchStores]);

  const fetchPreviousDayReconciliation = async (storeId, date) => {
    console.log("Fetching previous day's reconciliation for store:", storeId, "date:", date); // Debugging entry point
    try {
      const response = await axios.get(`${apiUrl}inventory/reconciliations/?store=${storeId}&date=${date}`);
      console.log("API response:", response.data); // Log the entire response
      const results = response.data.results; // Get the results array
      if (results.length > 0) {
        const previousDayEndingBalance = results[0].ending_balance || 0;
        console.log("Previous ending balance:", previousDayEndingBalance); // Specific field log
        setForm((prevForm) => ({
          ...prevForm,
          opening_balance: previousDayEndingBalance,
        }));
      }
    } catch (error) {
      console.error('Error fetching previous day reconciliation:', error);
    }
  };

  const fetchSalesAndExpenses = async (storeId, date) => {
    try {
      const salesResponse = await axios.get(
        `${apiUrl}inventory/get-sales/?store=${storeId}&date=${date}`
      );
      const expensesResponse = await axios.get(
        `${apiUrl}inventory/get-expenses/?store=${storeId}&date=${date}`
      );
      const gratuityResponse = await axios.get(
        `${apiUrl}inventory/get-gratuities/?store=${storeId}&date=${date}`
      );
      const TransfersResponse = await axios.get(
        `${apiUrl}inventory/bank-transfers/summary/?store=${storeId}&date=${date}`
      );

      const sales = salesResponse.data.total_sales || 0;
      const expenses = expensesResponse.data.total_expenses || 0;
      const gratuities = gratuityResponse.data.gratuities_total || 0;
      const transfers = TransfersResponse.data.net_transfers || 0;

      console.log("Net Transfers:", transfers);

      setForm((prevForm) => ({
        ...prevForm,
        sales_in_cash: sales,
        expenses_in_cash: expenses,
        gratuities: gratuities,
        transfers: transfers,
      }));
    } catch (error) {
      console.error("Error fetching sales and expenses:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value || "" }));
    if (name === 'date' && form.store) {
      fetchSalesAndExpenses(form.store, value);
      fetchPreviousDayReconciliation(form.store, value);
    }
    if (name === 'store') {
      console.log("Fetching all reconciliations for store:", value);
      setSelectedStore(value); // Update the selected store
      const selectedStoreObj = stores.find((store) => store.id === parseInt(value));
      setStoreName(selectedStoreObj ? selectedStoreObj.ShopName : "Unknown Store");
      fetchReconciliations(value); // Fetch all reconciliations for the store
      if (form.date) {
        fetchSalesAndExpenses(value, form.date);
        fetchPreviousDayReconciliation(value, form.date);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      store: form.store,
      date: form.date,
      opening_balance: form.opening_balance || 0, // Default to 0 if empty
      sales_in_cash: form.sales_in_cash || 0,    // Default to 0 if empty
      expenses_in_cash: form.expenses_in_cash || 0, // Default to 0 if empty
      gratuities: form.gratuities || 0, // Default to 0 if empty
      net_transfers: form.transfers || 0, // Include net transfers
    };

    if (latestReconciliation.is_closed) {
      // Open a new cash register
      try {
        const response = await axios.post(`${apiUrl}inventory/reconciliations/`, data);
        alert("Cash register opened successfully.");
        console.log("Response:", response.data);
      } catch (error) {
        console.error("Error opening cash register:", error);
        alert("Failed to open cash register.");
      }
    } else {
      // Logic for closing the cash register
      try {
        const closeResponse = await axios.patch(
          `${apiUrl}inventory/reconciliations/${latestReconciliation.id}/`,
          {
            is_closed: true,
            sales_in_cash: form.sales_in_cash,
            expenses_in_cash: form.expenses_in_cash,
            gratuities: form.gratuities || 0, // Default to 0 if empty
            net_transfers: form.transfers || 0, // Include net transfers
          }
        );
        alert("Cash register closed successfully.");
        console.log("Response:", closeResponse.data);
      } catch (error) {
        console.error("Error closing cash register:", error);
        alert("Failed to close cash register.");
      }
    }
    fetchReconciliations(form.store);
  };

  const computeEndingBalance = () => {
    const openingBalance = parseFloat(form.opening_balance) || 0;
    const salesInCash = parseFloat(form.sales_in_cash) || 0;
    const gratuities = parseFloat(form.gratuities) || 0;
    const transfers = parseFloat(form.transfers) || 0;
    const expensesInCash = parseFloat(form.expenses_in_cash) || 0;
    return openingBalance + salesInCash - expensesInCash - gratuities + transfers;
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchReconciliations(selectedStore, newPage);
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Cash Register Reconciliation
        </h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formStore">
            <Form.Label>Store</Form.Label>
            <Form.Control
              as="select"
              name="store"
              value={form.store}
              onChange={handleChange}
              required
            >
              <option value="">Select Store</option>
              {stores.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.ShopName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={form.date}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formOpeningBalance">
            <Form.Label>Opening Balance</Form.Label>
            <Form.Control
              type="number"
              name="opening_balance"
              value={form.opening_balance}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formSalesInCash">
            <Form.Label>Sales in Cash</Form.Label>
            <Form.Control
              type="number"
              name="sales_in_cash"
              value={form.sales_in_cash}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formGratuities">
            <Form.Label>Gratuities</Form.Label>
            <Form.Control
              type="number"
              name="gratuities"
              value={form.gratuities}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formExpensesInCash">
            <Form.Label>Expenses in Cash</Form.Label>
            <Form.Control
              type="number"
              name="expenses_in_cash"
              value={form.expenses_in_cash}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formTransfers">
            <Form.Label>Net Transfers</Form.Label>
            <Form.Control type="number" value={form.transfers} readOnly />
          </Form.Group>

          <Form.Group controlId="formEndingBalance">
            <Form.Label>Expected Ending Balance</Form.Label>
            <Form.Control
              type="number"
              value={computeEndingBalance()}
              readOnly
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            disabled={!form.store || !form.date} // Disable if store or date is not selected
            style={{ backgroundColor: (!form.store || !form.date) ? 'gray' : undefined }}
          >
            {latestReconciliation.is_closed ? "Open Cash Register" : "Close Cash Register"}
          </Button>
        </Form>
        {selectedStore && reconciliations.length > 0 && (
          <>
            <h4 style={{ marginTop: "20px" }}>Reconciliations for {storeName}</h4>
            <Table striped bordered hover style={{ marginTop: "10px" }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Opening Balance</th>
                  <th>Sales in Cash</th>
                  <th>Expenses in Cash</th>
                  <th>Gratuities</th>
                  <th>Net Transfers</th>
                  <th>Ending Balance</th>
                  <th>Cash Register Status</th>
                </tr>
              </thead>
              <tbody>
                {reconciliations.map((rec) => (
                  <tr key={rec.id}>
                    <td>{rec.date}</td>
                    <td>${rec.opening_balance}</td>
                    <td>${rec.sales_in_cash}</td>
                    <td>${rec.expenses_in_cash}</td>
                    <td>${rec.gratuities}</td>
                    <td>${rec.net_transfers}</td>
                    <td>${rec.ending_balance}</td>
                    <td>{rec.is_closed ? "Closed" : "Open"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  disabled={currentPage === i + 1}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CashReconciliation;
