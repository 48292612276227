import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";
import ClientSalesHistory from "../Sales/ClientSalesModal.js";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./ShopClients.css"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";


const ShopClients = () => {
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for search term
  const [searchTerm, setSearchTerm] = useState("");

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleOpenModal = (itemId = null) => {
    setCurrentItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch(`${apiUrl}api/clients/${id}/`);
        const data = await response.json();
        setBookings(data);
        setFilteredBookings(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [id, apiUrl]);

  // Filter function based on search term
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const filtered = bookings.filter((booking) => {
        const fullName = `${booking.FirstName} ${booking.LastName}`.toLowerCase();
        const petNames = booking.pets
          ? booking.pets.map((pet) => pet.PetName.toLowerCase()).join(", ")
          : "";
        return (
          fullName.includes(searchTerm.toLowerCase()) ||
          petNames.includes(searchTerm.toLowerCase())
        );
      });
      setFilteredBookings(filtered);
      setCurrentPage(1); // Reset to the first page on search
    }, 300);

    return () => clearTimeout(debounceTimer); // Cleanup debounce timer
  }, [searchTerm, bookings]);

  // Paginated Data
  const totalRows = filteredBookings.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentRows = filteredBookings.slice(startIndex, startIndex + rowsPerPage);

  const changePage = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="row">
      <div className="col-md-3">
        <Sidebar />
      </div>
      <div className="col-md-9">
        <div className="client-list-header">
          <h3 className="page-title">Active Client List</h3>
          <div className="action-container">
            <Link to="/shop-add-clients">
              <button className="btn btn-primary add-client-btn">
                <FontAwesomeIcon icon={faPlus} /> Add New Client
              </button>
            </Link>
            <div className="search-container">
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="text"
                placeholder="Search by client or pet name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
              <i className="fa fa-search search-icon"></i>
            </div>
          </div>
        </div>
        <div className="table-container">
          <Table striped bordered hover className="styled-table">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Pet</th>
                <th>Preferred Contact</th>
                <th>Address</th>
                <th>Transaction</th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((booking, index) => (
                <tr
                  key={index}
                  style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}
                >
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/client-profile/${booking.id}`} className="client-link">
                      {booking.FirstName ? `${booking.FirstName}` : "N/A"}
                    </Link>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/client-profile/${booking.id}`} className="client-link">
                      {booking.LastName ? `${booking.LastName}` : "N/A"}
                    </Link>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.pets && booking.pets.length > 0
                      ? booking.pets.map((pet, index) => (
                          <span key={index}>
                            <Link to={`/pet-profile/${pet.id}`} className="pet-link">
                              {pet.PetName}
                            </Link>
                            {index < booking.pets.length - 1 && ", "}
                          </span>
                        ))
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.contacts && booking.contacts.length > 0
                      ? booking.contacts
                          .map((contact) => contact.contact_type)
                          .join(", ")
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.Address ? booking.Address : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="primary"
                      onClick={() => handleOpenModal(booking.id)}
                      className="transaction-btn"
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="pagination-container">
          <button
            className="pagination-button"
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="pagination-page-number">{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            className="pagination-button"
            onClick={() => changePage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClientSalesHistory clientId={currentItemId} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShopClients;
