import React, { useState, useEffect } from "react";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Sidebar from "../Design/TodayBookingSidebar.js";

import { useSelector } from "react-redux";

const ShopAddClient = () => {
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  //const { shopName } = useParams();
  //const { id } = useParams();
  const [clientData, setClientData] = useState({
    Prefix: "",
    FirstName: "",
    LastName: "",
    //ServicesCateGory: "",
    Address: "",
    AddressTwo: "",
    City: "",
    StateCountry: "",
    PostalCode: "",
    Area: "",
    LocalTaxRate: "",
    ClientRefferalSource: "",
    ClientSince: "",
    LocalTaxRate: "",
    SpecialNotes: "",
    Status: "good",
    contacts: [],
  });

  const STATUS_OPTION = [
    { value: "vip", label: "VIP" },
    { value: "good", label: "Good" },
    { value: "caution", label: "Caution" },
    { value: "warning", label: "Warning" },
    { value: "banned", label: "Banned" },
    { value: "inactive", label: "Inactive" },
  ];

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });

    if (name === "PostalCode" && value.trim().length > 0) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: value,
              components: "country:MX",
              key: googleMapsApiKey,
            },
          }
        );

        if (response.data.status === "OK") {
          const addressComponents = response.data.results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const areaComponent = addressComponents.find(
            (component) =>
              component.types.includes("sublocality") ||
              component.types.includes("political")
          );

          setClientData((prevState) => ({
            ...prevState,
            City: cityComponent ? cityComponent.long_name : "",
            Area: areaComponent ? areaComponent.long_name : "",
          }));
        }
      } catch (error) {
        console.error(
          "Error fetching data from Google Maps Geocoding API",
          error
        );
      }
    }
  };

  const handleContactChange = (e, index) => {
    const { name, value } = e.target;
    const updatedContacts = [...clientData.contacts];
    updatedContacts[index][name] = value;
    setClientData({ ...clientData, contacts: updatedContacts });
  };

  const addContact = () => {
    setClientData({
      ...clientData,
      contacts: [
        ...clientData.contacts,
        {
          contact_type: "",
          contact_value: "",
          contact_by: "",
          preferred_contact: "",
        },
      ],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...clientData,
      contacts: clientData.contacts.map((contact) => ({
        ...contact,
        primary: contact.primary || false,
      })),
    };
    try {
      const response = await axios.post(
        `${apiUrl}api/clients/${id}/`,
        dataToSubmit
      );
      alert("Client is added successfuly!");

      const clientId = response.data.id;
      window.location.href = `/shop-add-pet/${clientId}`;
    } catch (error) {
      console.error("Error adding client:", error);
      alert("Check all fields!");
    }
  };

  const handlePrimaryChange = (index) => {
    const updatedContacts = [...clientData.contacts];
    updatedContacts[index].primary = !updatedContacts[index].primary;
    setClientData({ ...clientData, contacts: updatedContacts });
  };

  const handlePhoneChange = (value, index) => {
    const updatedContacts = [...clientData.contacts];
    updatedContacts[index]["contact_value"] = value;
    setClientData({ ...clientData, contacts: updatedContacts });
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Add New Client
        </h3>
        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
        <form onSubmit={handleSubmit} className="container mt-5">
            {/* Client Status Dropdown */}
            <div className="row mb-3">
              <div className="col-md-12">
                <label>Client Status (required)</label>
                <select
                  name="Status"
                  value={clientData.Status}
                  onChange={handleChange}
                  className="form-control"
                  required
                >
                  {STATUS_OPTION.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label>First Name (required)</label>
                <input
                  type="text"
                  name="FirstName"
                  value={clientData.FirstName}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Last Name (required)</label>
                <input
                  type="text"
                  name="LastName"
                  value={clientData.LastName}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label>Address (optional)</label>
                <input
                  type="text"
                  name="Address"
                  value={clientData.Address}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label>Address Two (optional)</label>
                <input
                  type="text"
                  name="AddressTwo"
                  value={clientData.AddressTwo}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label>Postal Code (optional)</label>
                <input
                  type="text"
                  name="PostalCode"
                  value={clientData.PostalCode}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

              <div className="col-md-6">
                <label>City (optional)</label>
                <input
                  type="text"
                  name="City"
                  value={clientData.City}
                  //readOnly
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label>State/Country (optional)</label>

                <select
                  name="StateCountry"
                  value={clientData.StateCountry}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="Aguascalientes">Aguascalientes</option>
                  <option value="Baja California">Baja California</option>
                  <option value="Baja California Sur">
                    Baja California Sur
                  </option>
                  <option value="Campeche">Campeche</option>
                  <option value="Chiapas">Chiapas</option>
                  <option value="Chihuahua">Chihuahua</option>
                  <option value="Ciudad de México">Ciudad de México</option>
                  <option value="Coahuila">Coahuila</option>
                  <option value="Colima">Colima</option>
                  <option value="Durango">Durango</option>
                  <option value="Guanajuato">Guanajuato</option>
                  <option value="Guerrero">Guerrero</option>
                  <option value="Hidalgo">Hidalgo</option>
                  <option value="Jalisco">Jalisco</option>
                  <option value="México">México</option>
                  <option value="Michoacán">Michoacán</option>
                  <option value="Morelos">Morelos</option>
                  <option value="Nayarit">Nayarit</option>
                  <option value="Nuevo León">Nuevo León</option>
                  <option value="Oaxaca">Oaxaca</option>
                  <option value="Puebla">Puebla</option>
                  <option value="Querétaro">Querétaro</option>
                  <option value="Quintana Roo">Quintana Roo</option>
                  <option value="San Luis Potosí">San Luis Potosí</option>
                  <option value="Sinaloa">Sinaloa</option>
                  <option value="Sonora">Sonora</option>
                  <option value="Tabasco">Tabasco</option>
                  <option value="Tamaulipas">Tamaulipas</option>
                  <option value="Tlaxcala">Tlaxcala</option>
                  <option value="Veracruz">Veracruz</option>
                  <option value="Yucatán">Yucatán</option>
                  <option value="Zacatecas">Zacatecas</option>
                </select>
              </div>
              <div className="col-md-6">
                <label>Area (optional)</label>
                <input
                  type="text"
                  name="Area"
                  value={clientData.Area}
                  //readOnly
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label>Client Refferal Source (optional)</label>
                <select
                  name="ClientRefferalSource"
                  value={clientData.ClientRefferalSource}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select a source</option>
                  <option value="Search Results - Google">
                    Search Results - Google
                  </option>
                  <option value="Search Results - Bing">
                    Search Results - Bing
                  </option>
                  <option value="Search Results - Yahoo">
                    Search Results - Yahoo
                  </option>
                  <option value="Social - Facebook">Social - Facebook</option>
                  <option value="Social - Twitter">Social - Twitter</option>
                  <option value="Social - Instagram">Social - Instagram</option>
                  <option value="Referral - Friend or Family">
                    Referral - Friend or Family
                  </option>
                  <option value="Referral - Groomer">Referral - Groomer</option>
                  <option value="Referral - Pet Store">
                    Referral - Pet Store
                  </option>
                  <option value="Referral - Kennel">Referral - Kennel</option>
                  <option value="Referral - Veterinarian">
                    Referral - Veterinarian
                  </option>
                  <option value="Returning Client">Returning Client</option>
                  <option value="Saw Sign - Drive by">
                    Saw Sign - Drive by{" "}
                  </option>
                  <option value="Advertisement - Online / Digital">
                    Advertisement - Online / Digital
                  </option>
                  <option value="Advertisement - Print">
                    Advertisement - Print
                  </option>
                  <option value="Advertisement - Radio">
                    Advertisement - Radio
                  </option>
                  <option value="Walk In">Walk In</option>
                  <option value="Groupon">Groupon</option>
                  <option value="ValPack">ValPack</option>
                  <option value="Website">Website</option>
                  <option value="Yelp">Yelp</option>
                  <option value="Other Source - Not Listed">
                    Other Source - Not Listed
                  </option>
                </select>
              </div>
              <div className="col-md-6">
                <label>Client Since (optional)</label>
                <input
                  type="date"
                  name="ClientSince"
                  value={clientData.ClientSince}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
              </div>
              <div className="col-md-6">
                <label>Special Notes (optional)</label>
                <textarea
                  name="SpecialNotes"
                  value={clientData.SpecialNotes}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label></label>
              </div>
              {clientData.contacts.map((contact, index) => (
                <div key={index} className="col-md-4">
                  <label>Contact Type (required)</label>
                  <select
                    name="contact_type"
                    value={contact.contact_type}
                    onChange={(e) => handleContactChange(e, index)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Home">Home</option>
                    <option value="Mobile">Mobile</option>
                    <option value="Email">Email</option>
                    <option value="Work">Work</option>
                  </select>
                  <label className="mt-2">Contact (required)</label>
                  {contact.contact_type === "Mobile" ? (
                    <PhoneInput
                      international
                      defaultCountry="MX"
                      value={contact.contact_value}
                      onChange={(value) => handlePhoneChange(value, index)}
                    />
                  ) : (
                    <input
                      type="text"
                      name="contact_value"
                      value={contact.contact_value}
                      onChange={(e) => handleContactChange(e, index)}
                      className="form-control"
                    />
                  )}
                  {!contact.primary && (
                    <button
                      type="button"
                      onClick={() => handlePrimaryChange(index)}
                      className="btn btn-primary mt-4 mb-4"
                    >
                      Set as Primary
                    </button>
                  )}
                  <br />
                  {contact.primary && (
                    <p className="text-success mt-2 mb-4">Primary Contact</p>
                  )}
                  <label>Contact By (required)</label>
                  <input
                    type="text"
                    name="contact_by"
                    value={contact.contact_by}
                    onChange={(e) => handleContactChange(e, index)}
                    className="form-control"
                  />
                  <label className="mt-2">Preferred Contact:</label>
                  <select
                    name="preferred_contact"
                    value={contact.preferred_contact}
                    onChange={(e) => handleContactChange(e, index)}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Sms">SMS/Text</option>
                    <option value="Phone">Phone</option>
                    <option value="Email">Email</option>
                  </select>
                </div>
              ))}
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <button
                  type="button"
                  onClick={addContact}
                  className="btn btn-primary"
                >
                  Add Contact Form
                </button>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <button type="submit" className="btn btn-success">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopAddClient;
