import React, { useState, useEffect, useRef} from "react";
import { Table, Modal, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { CSVLink } from "react-csv";
import CommissionDetails from "./EmployeeCommissionComponent/CommissionDetails"; 

/* REACT - REDUX */
import { useSelector } from "react-redux";

const EmployeeCommission = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [result, setResult] = useState({ summary: [], details: [] });

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [expandedDate, setExpandedDate] = useState(null);
  const [modalDetails, setModalDetails] = useState([]);

  const reportRef = useRef();

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
        console.log(">>>>>>>", response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [apiUrl, userInfo.token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {};
    if (startDate) data.start_date = startDate;
    if (endDate) data.end_date = endDate;
    if (selectedUser && selectedUser !== "all") data.shop = selectedUser;

    if (Object.keys(data).length === 0) {
      alert("Please select at least one filter.");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}payroll/commissions-report/`, data, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      console.log("API Response:", response.data);

      if (response.data) {
        setResult({
          summary: response.data.summary || [],
          details: response.data.details || [],
        });
      } else {
        console.error("Unexpected API response structure:", response.data);
        setResult({ summary: [], details: [] });
      }
    } catch (error) {
      console.error("Error fetching report:", error);
      setResult({ summary: [], details: [] });
    }
  };

  // Group details by date for modal display
  const handleRowClick = (date) => {
    const detailsForDate = result.details.filter((detail) => detail.local_date === date);
    console.log("Details for selected date:", detailsForDate);
    setModalDetails(detailsForDate);
    setExpandedDate(date);
  };

  const handlePrint = () => {
    const printContent = reportRef.current;
    const WindowPrint = window.open("", "", "width=900,height=650");
    WindowPrint.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    WindowPrint.focus();
    WindowPrint.print();
    WindowPrint.close();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex flex-column">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate || ""}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>End Date</label>
            <input
              type="date"
              value={endDate || ""}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>Shop</label>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="all">Select Shop</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.ShopName}
                </option>
              ))}
            </select>
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>
      {result.summary.length > 0 && (
        <div>
          <div className="d-flex justify-content-end mb-3">
            <CSVLink
              data={result.summary}
              headers={[
                { label: "Employee", key: "employee__first_name" },
                { label: "Date", key: "date" },
                { label: "Total Earnings", key: "total_earnings" },
                { label: "Total Commission", key: "total_commission" },
              ]}
              filename={"employee-commission-report.csv"}
              className="btn btn-primary me-2"
            >
              Export to CSV
            </CSVLink>
            <button className="btn btn-secondary" onClick={handlePrint}>
              Print
            </button>
          </div>
          <div ref={reportRef}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Employee First Name</th>
                  <th>Date</th>
                  <th>Total Earnings</th>
                  <th>Total Commission</th>
                </tr>
              </thead>
              <tbody>
                {result.summary.map((item) => (
                  <tr key={`${item.employee__first_name}-${item.local_date}`} onClick={() => handleRowClick(item.local_date)}>
                    <td>{item.employee__first_name}</td>
                    <td>{item.local_date}</td>
                    <td>${item.total_earnings}</td>
                    <td>${item.total_commission}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      <Modal show={!!expandedDate} onHide={() => setExpandedDate(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Details for {expandedDate}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommissionDetails commissions={modalDetails} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setExpandedDate(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmployeeCommission;
