import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";
import ShopBookingsDetail from "./shopBookingsDetail.js";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const BookingsShop = () => {
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;
  
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timezoneLoading, setTimezoneLoading] = useState(true);
  const [activeBookingId, setActiveBookingId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleOpenModal = (bookingId) => {
    setActiveBookingId(bookingId);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const fetchShopDetailsAndBookings = async () => {
      try {
        // Fetch shop details and bookings concurrently
        const [bookingsResponse] = await Promise.all([
          fetch(`${apiUrl}api/bookings/${id}/`),
        ]);
        
        let bookingsData = await bookingsResponse.json();

        // Sort bookings by start time
        bookingsData = bookingsData.sort((a, b) => new Date(a.start) - new Date(b.start));
        
        setBookings(bookingsData);

        setTimezoneLoading(false); // Mark timezone as loaded
        setLoading(false); // Mark overall loading as complete
      } catch (error) {
        setLoading(false); // Ensure loading is turned off even on error
      }
    };

    fetchShopDetailsAndBookings();
  }, [id, apiUrl]);

  const formatDate = (dateString) => {
    // Parse the ISO date string manually (e.g., "2025-01-10T12:00:00Z")
    const [_, timePart] = dateString.split("T");
    const [hours, minutes] = timePart.replace("Z", "").split(":"); // Extract hours, minutes (ignore "Z")

    // Construct formatted date and time string
    const formattedDate = `${hours}:${minutes}`;

    return formattedDate;
  };

  if (loading || timezoneLoading) {
    return <div>Loading...</div>;
  }
  
  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Today's Bookings
        </h3>

        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>Client</th>
                <th>Pet</th>
                <th>Start</th>
                <th>End</th>
                <th>Booking Type</th>
                <th>Calendar</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, index) => (
                <tr
                  key={index}
                  style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}
                >
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/booking`}>
                      {booking.owner ? `${booking.owner.FirstName}` : "N/A"}
                    </Link>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.petname ? booking.petname.PetName : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {formatDate(booking.start)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {formatDate(booking.end)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.booking_type}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.calendar ? booking.calendar.title : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Booking Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!activeBookingId ? (
            <div></div>
          ) : (
            <ShopBookingsDetail shopName={shopName} id={activeBookingId} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BookingsShop;
