import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Modal from "react-modal";
import {
  Form,
  Table,
  Container,
  Modal as BootstrapModal,
  Button,
} from "react-bootstrap";
import Sidebar from "../Design/ShopDetailsSidebar.js";
import ShopBookingsDetail from "./shopBookingsDetail.js";
import CustomToolbar from "./CustomToolbar.js";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faTimes } from "@fortawesome/free-solid-svg-icons";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { logout } from "../../actions/userActions";

import "./Style.css";

const Booking = () => {
  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  /* HANDLER */
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const [showBootstrapModal, setShowBootstrapModal] = useState(false);

  const handleOpenBootstrapModal = () => setShowBootstrapModal(true);
  const handleCloseBootstrapModal = () => setShowBootstrapModal(false);

  const [isPetModalOpen, setIsPetModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [clients, setClients] = useState([]);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const localizer = momentLocalizer(moment);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [calendarType, setCalendarType] = useState(null);
  const [additionalnotes, setAdditionalnotes] = useState("");
  const [serviceType, setServiceType] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [events, setEvents] = useState([]);

  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const [DogList, setDogList] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSlotSelected, setIsSlotSelected] = useState(false);
  const [selectedPetClient, setSelectedPetClient] = useState("");
  const [isPetModal, isPetModalSelected] = useState(false);
  const [clientData, setClientData] = useState({
    shop: id,
    Prefix: "",
    FirstName: "",
    LastName: "",
    Address: "",
    AddressTwo: "",
    City: "",
    StateCountry: "",
    PostalCode: "",
    Area: "",
    LocalTaxRate: "",
    ClientRefferalSource: "",
    ClientSince: "",
    SpecialNotes: "",
    contacts: [
      {
        contact_type: "",
        contact_value: "",
        contact_by: "",
        preferred_contact: "",
        primary: false,
      },
    ],
  });

  const [serviceSearchTerm, setServiceSearchTerm] = useState("");
  const [serviceModalOpen, setServiceModalOpen] = useState(false);

  // for service search results and quantity handling
  const [searchResults, setSearchResults] = useState([]);

  const handleClientChange = async (field, value) => {
    setClientData({ ...clientData, [field]: value });

    if (field === "PostalCode" && value.trim().length > 0) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: value,
              components: "country:MX",
              key: googleMapsApiKey,
            },
          }
        );

        if (response.data.status === "OK") {
          const addressComponents = response.data.results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const areaComponent = addressComponents.find(
            (component) =>
              component.types.includes("sublocality") ||
              component.types.includes("political")
          );


          setClientData((prevState) => ({
            ...prevState,
            City: cityComponent ? cityComponent.long_name : "",
            Area: areaComponent ? areaComponent.long_name : "",
          }));
        }
      } catch (error) {
        console.error(
          "Error fetching data from Google Maps Geocoding API",
          error
        );
      }
    }
  };

  const openPetModal = (id) => {
    setIsPetModalOpen(true);
    setSelectedPetClient(id);
  };

  const closePetModal = () => {
    setIsPetModalOpen(false);
  };

  const handleClientClick = (client) => {
    setSelectedClientId(client.id);
    setSelectedClient(client);
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...clientData.contacts];
    if (updatedContacts[index]) {
      updatedContacts[index][field] = value;
      setClientData({ ...clientData, contacts: updatedContacts });
    }
  };

  const handlePhoneChange = (value, index) => {
    const updatedContacts = [...clientData.contacts];
    updatedContacts[index]["contact_value"] = value;
    setClientData({ ...clientData, contacts: updatedContacts });
  };

  const addContact = () => {
    setClientData({
      ...clientData,
      contacts: [
        ...clientData.contacts,
        {
          contact_type: "",
          contact_value: "",
          contact_by: "",
          preferred_contact: "",
          primary: false,
        },
      ],
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}api/clients1/`, clientData);
      alert("client added!");

      setClients([...clients, response.data]);

      closeNewUserModal();
    } catch (error) {
      if (error.response) {
        console.log("Server responded with an error:", error.response.data);
        console.log("Status code:", error.response.status);
        alert("There is some error, check all fields!");
      } else if (error.request) {
        console.log("No response received from the server.");
      } else {
        console.log("Error during request setup:", error.message);
      }
    }
  };

  // data for pet
  const [petData, setPetData] = useState({
    PetName: "",
    PetType: "",
    Gender: "",
    Breed: "",
    Spayed: false,
    Weight: "",
    CoatColor: "",
    BirthDate: "",
    Allergies: "",
    AdditionalNote: "",
    VaccinationRecord: "",
    DateAdministered: "",
    NextDue: "",
    social_url: "",
    //HealthFlags: [],
    //ServicesFlags: [],
    //GuestFlags: [],
    flags: [],
    Owner: selectedPetClient,
    PetImage: null,
  });

  const [breeds, setBreeds] = useState([]);
  const [breedSuggestions, setBreedSuggestions] = useState([]);

  // Fetch breeds from the API
  useEffect(() => {
    const fetchBreeds = async () => {
      try {
        const response = await fetch(`${apiUrl}api/breeds/`);
        const data = await response.json();
        setBreeds(data);
      } catch (error) {
        console.error("Failed to fetch breeds:", error);
      }
    };

    fetchBreeds();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "Breed" && value.length >= 3) {
      const filteredBreeds = breeds
        .filter((breed) =>
          breed.name.toLowerCase().includes(value.toLowerCase())
        )
        .map((breed) => breed.name);
      setBreedSuggestions(filteredBreeds);
    }
  };

  // Handler for selecting a breed from suggestions
  const handleSelectBreed = (breed) => {
    setPetData({ ...petData, Breed: breed });
    setBreedSuggestions([]);
  };

  const [availableFlags, setAvailableFlags] = useState({
    health: [],
    service: [],
    guest: [],
  });

  // Fetch available flags from the backend
  useEffect(() => {
    const fetchFlags = async (flagType) => {
      const response = await axios.get(`${apiUrl}api/flags/${flagType}/`);
      return response.data;
    };

    const flagTypes = ["health", "service", "guest"];
    flagTypes.forEach((type) => {
      fetchFlags(type).then((flags) => {
        setAvailableFlags((prev) => ({ ...prev, [type]: flags }));
      });
    });
  }, [apiUrl]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setPetData((prev) => ({
      ...prev,
      flags: checked
        ? [...prev.flags, value]
        : prev.flags.filter((flag) => flag !== value),
    }));
  };

  const renderFlagCheckboxes = (flagType) =>
    availableFlags[flagType].map((flag) => (
      <div key={flag.id} className="form-check">
        <input
          type="checkbox"
          name="flags"
          value={flag.id}
          checked={petData.flags.includes(flag.id.toString())}
          onChange={handleCheckboxChange}
          className="form-check-input"
        />
        <label className="form-check-label">{flag.name}</label>
      </div>
    ));

  const handleSubmitq = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("PetName", petData.PetName);
      formData.append("PetType", petData.PetType);
      formData.append("Gender", petData.Gender);
      formData.append("Breed", petData.Breed);
      formData.append("Spayed", petData.Spayed);
      formData.append("Weight", petData.Weight);
      formData.append("CoatColor", petData.CoatColor);
      formData.append("BirthDate", petData.BirthDate);
      formData.append("Allergies", petData.Allergies);
      formData.append("AdditionalNote", petData.AdditionalNote);
      formData.append("VaccinationRecord", petData.VaccinationRecord);
      formData.append("DateAdministered", petData.DateAdministered);
      formData.append("NextDue", petData.NextDue);
      formData.append("social_url", petData.social_url);
      //formData.append("flags", petData.flags);
      petData.flags.forEach((flagId) => {
        formData.append("flags", flagId);
      });
      //formData.append("ServicesFlags", petData.ServicesFlags);
      //formData.append("GuestFlags", petData.GuestFlags);
      formData.append("PetImage", petData.PetImage);
      formData.append("Owner", selectedPetClient);
      const response = await fetch(`${apiUrl}api/PetCreateView/`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit data");
      }

      const addedPet = await response.json();
      alert("pet added!");

      // Update the clients state to include the new pet
      setClients(
        clients.map((client) => {
          if (client.id === selectedPetClient) {
            return {
              ...client,
              pets: [...client.pets, addedPet],
            };
          } else {
            return client;
          }
        })
      );

      setSelectedClient((prevClient) => ({
        ...prevClient,
        pets: [...prevClient.pets, addedPet],
      }));

      // Close the pet modal
      closePetModal();
    } catch (error) {
      console.error("Error:", error.message);
      alert("check all fields, add image!");
    }
  };

  // data for pet ends

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredClients = clients.filter(
    (client) =>
      `${client.Prefix} ${client.FirstName} ${client.LastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      client.pets.some((pet) =>
        pet.PetName.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/clients/`, {
          params: { search: searchTerm },
        });
        setClients(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchData();
  }, [searchTerm]);

  const handleSelectSlot = (slotInfo) => {
    if (selectedSlot) {
      const updatedEvents = events.filter(
        (event) => !moment(event.start).isSame(selectedSlot.start, "minutes")
      );
      setEvents(updatedEvents);
    }

    const newEvent = {
      id: Math.random(),
      title: "",
      start: slotInfo.start,
      end: slotInfo.end,
      booking: "book",
      allDay: false,
    };

    //newEvent.tooltip = formatTooltipText(newEvent);
    setEvents((prevEvents) => [...prevEvents, newEvent]);
    setSelectedDate(moment(slotInfo.start));
    setStartTime(moment(slotInfo.start));
    setEndTime(moment(slotInfo.start).add(45, "minutes"));
    setIsSlotSelected(true);
    setSelectedSlot(slotInfo);
  };

  const handleDateChange = (e) => {
    const newDate = moment(e.target.value, "YYYY-MM-DD");
    setSelectedDate(newDate);
    setStartTime(newDate);
    setEndTime(moment(newDate).add(1, "hours"));
  };

  const handleCalendarTypeChange = (e) => {
    setCalendarType(e.target.value);
  };

  const handleServiceTypeChange = (e) => {
    setServiceType(e.target.value);
  };

  const handleServiceToggle = (service) => {
    setSelectedServices((prevServices) => {
      const isServiceSelected = prevServices.some((s) => s.id === service.id);
      if (isServiceSelected) {
        return prevServices.filter((s) => s.id !== service.id);
      } else {
        return [
          ...prevServices,
          { id: service.id, name: service.name, quantity: 1 },
        ];
      }
    });
  };

  const isSelectedService = (serviceId) => {
    return selectedServices.some((s) => s.id === serviceId);
  };

  const openModal = () => {
    if (selectedDate && calendarType) {
      setIsModalOpen(true);
    } else {
      alert("Please select a date and calendar type");
    }
  };

  const DogDetailModel = () => {
    setDogList(true);
  };
  const closeModalDog = () => {
    setDogList(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openNewUserModal = () => {
    setIsNewUserModalOpen(true);
  };

  const closeNewUserModal = () => {
    setIsNewUserModalOpen(false);
  };

  const handleSubmit1 = () => {
    if (calendarType && startTime && endTime) {
      setReviewModalOpen(true);
    } else {
      alert("Please select a date, calendar type, start time, and end time");
    }
  };

  // pet id's (case -> multiple pets)
  useEffect(() => {
    if (selectedClient) {
      const petIds = selectedClient.pets.map((pet) => pet.id);
      setSelectedPets(petIds);
    }
  }, [selectedClient]);

  const [selectedPets, setSelectedPets] = useState([]);

  const handlePetSelection = (petId) => {
    const isSelected = selectedPets.includes(petId);
    if (isSelected) {
      setSelectedPets(selectedPets.filter((id) => id !== petId));
    } else {
      setSelectedPets([...selectedPets, petId]);
    }
  };

  const finalizeBooking = async () => {
    if (selectedDate && calendarType && startTime && endTime) {
      try {
        // Map selectedServices to include both ID and quantity
        const serviceData = selectedServices.map((service) => ({
          id: service.id,
          quantity: service.quantity,
        }));


        const bookingData = {
          shop: id,
          title: decodeURIComponent(shopName),
          start:
            selectedDate.format("YYYY-MM-DD") + " " + startTime.format("HH:mm"),
          end:
            selectedDate.format("YYYY-MM-DD") + " " + endTime.format("HH:mm"),
          calendar: calendarType,
          booking_type: "appointment",
          //service_ids: serviceData,
          owner: selectedClient.id,
          petname: selectedPets,
          //petname: selectedClient.pets[0].id,
          additional_details: additionalnotes,
        };

        const response = await axios.post(
          `${apiUrl}api/bookings/`,
          bookingData
        );
        const bookingId = response.data.id;

        // For each selected service, create a SelectedService instance
        for (const selectedService of selectedServices) {
          const selectedServiceData = {
            booking: bookingId,
            service: selectedService.service.id,
            quantity: selectedService.quantity,
          };

          // Save selected service
          await axios.post(
            `${apiUrl}api/selected-services/`,
            selectedServiceData
          );
        }


        window.location.href = `/appointment-bookings`;
      } catch (error) {
        console.error("Error creating booking:", error);
      }
    } else {
      alert("Please select a date, calendar type, start time, and end time");
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/services/`);
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  const handleServiceChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(
      (option) => option.value
    );
    setSelectedServices(selectedOptions);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleAdditonalNotesChange = (event) => {
    setAdditionalnotes(event.target.value);
  };

  const [calendarOptions, setCalendarOptions] = useState([]);

  useEffect(() => {
    const fetchCalendars = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/calendars/`);
        setCalendarOptions(response.data);
      } catch (error) {
        console.error("Failed to fetch calendars", error);
      }
    };

    fetchCalendars();
  }, []);

  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedBookingIds, setSelectedBookingIds] = useState([]);

  useEffect(() => {
    fetch(`${apiUrl}api/more-appointment-bookings/${id}/`)
      .then((response) => response.json())
      .then((data) => {
        const formattedBookings = data.map((booking) => {
          const start = moment.utc(booking.start).format("YYYY-MM-DDTHH:mm:ss");
          const end = moment.utc(booking.end).format("YYYY-MM-DDTHH:mm:ss");

          const petNamesArray = booking.pet_names.split(', ');
          const petBreedsArray = booking.pet_breeds.split(', ');
          // Ensure both arrays have the same length
          const length = Math.max(petNamesArray.length, petBreedsArray.length);
          const petDetailsArray = [];

          for (let i = 0; i < length; i++) {
            const petName = petNamesArray[i] || "Unknown Pet";
            const petBreed = petBreedsArray[i] || petBreedsArray[0] || "Unknown Breed"; // Fallback to the first breed if missing
            petDetailsArray.push(`${petName} (${petBreed})`);
          }

          const petDetails = petDetailsArray.join(', ');

          return {
            id: booking.client_id,
            booking_ids: booking.booking_ids,
            title: petDetails,
            start: new Date(start),
            end: new Date(end),
            owner: booking.client_id,
            pet_names: booking.pet_names,
            client_names: booking.client_names,
            is_paid: booking.is_paid,
            current_status: booking.current_status,
            calendar_color: booking.calendar_color,
            allDay: false,
          };
        });
        setBookings(formattedBookings);
        setEvents(formattedBookings);
      })
      .catch((error) => console.error("Error fetching bookings:", error));
  }, [id, apiUrl]);

  const handleSelectEvent = (event) => {
    setSelectedBookingIds(event.booking_ids || []);
    setSelectedBookingId(event.id);
    setShowBootstrapModal(true);
  };

  const formatTooltipText = (event) => {
    const startTime = moment(event.start).format("D MMM, YYYY - h:mmA");
    const endTime = moment(event.end).format("h:mmA");

    return `${event.pet_names}
Client: ${event.client_names}
Date: ${startTime}-${endTime}
Click for details`;
  };

  const [selectedBookingId, setSelectedBookingId] = useState(null);

  // Function to handle service search
  const handleServiceSearch = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}inventory/inventory-services/search/?query=${serviceSearchTerm}`
      );
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (serviceSearchTerm) {
      handleServiceSearch();
    }
  }, [serviceSearchTerm]);

  // Function to select service and set initial quantity
  const handleSelectService = (service) => {
    setSelectedServices((prevSelectedServices) => {
      // Prevent adding the same service multiple times
      if (
        prevSelectedServices.some(
          (selectedService) => selectedService.service.id === service.id
        )
      ) {
        return prevSelectedServices;
      }
      return [...prevSelectedServices, { service, quantity: 1 }];
    });
  };

  // Function to update quantity for a selected service
  const handleQuantityChange = (serviceId, quantity) => {
    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.map((selectedService) => {
        if (selectedService.service.id === serviceId) {
          return { ...selectedService, quantity: parseInt(quantity, 10) };
        }
        return selectedService;
      })
    );
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Container>
          <h2
            className="booking-heading mb-4 mt-2"
            style={{
              paddingBottom: "10px",
              fontWeight: "700",
              color: "#282c34",
            }}
          >
            New Appointment
          </h2>
          <div
            style={{
              backgroundColor: "white",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
            className="px-4"
          >
            <div style={{ height: "600px" }}>
              <Calendar
                localizer={localizer}
                events={events.map((booking) => ({
                  ...booking,
                  start: moment(booking.start).toDate(),
                  end: moment(booking.end).toDate(),
                  tooltip: formatTooltipText(booking),
                }))}
                startAccessor="start"
                endAccessor="end"
                defaultView="week"
                tooltipAccessor="tooltip"
                selectable
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                components={{
                  toolbar: CustomToolbar,
                  event: ({ event }) => (
                    <div
                    className="rbc-event-content"
                      key={event.id}
                      onClick={() => handleSelectEvent(event)}
                      style={{
                        backgroundColor:
                          event.current_status === "Cancel"
                            ? "grey"
                            : event.is_paid
                            ? "grey"
                            : event.calendar_color || "inherit",
                        borderColor:
                          event.current_status === "Cancel"
                            ? "grey"
                            : event.is_paid
                            ? "grey"
                            : event.calendar_color || "inherit",
                        borderWidth: "1px",
                        color: "white",
                        padding: "2px",
                        borderRadius: "3px",
                      }}
                    >
                      <div style={{ color: "white", fontSize: "12px" }}>
                        {event.title} <br /><br />
                        {moment(event.start).format('hh:mm A')} - {moment(event.end).format('hh:mm A')}
                      </div>
                      {event.current_status === "Cancel" ? (
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{ marginLeft: "5px", color: "white" }}
                        />
                      ) : event.is_paid ? (
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          style={{ marginLeft: "5px", color: "white" }}
                        />
                      ) : null}
                    </div>
                  ),
                }}
                min={new Date(1970, 1, 1, 8, 30)}  // 8:30 AM
                max={new Date(1970, 1, 1, 20, 0)} // 8:00 PM
              />
            </div>

            <div className="calendar-type-container">
              <label>Select Calendar</label>
              <select value={calendarType} onChange={handleCalendarTypeChange}>
                <option value="" disabled selected hidden>
                  Please select a calendar
                </option>
                {calendarOptions.map((calendar) => (
                  <option
                    key={calendar.id}
                    value={calendar.id}
                    style={{ background: calendar.color, color: "white" }}
                  >
                    {calendar.title}
                  </option>
                ))}
              </select>
            </div>

            {selectedDate && (
              <div
                className={`time-selection-container ${
                  isSlotSelected ? "selected" : ""
                }`}
              >
                <p>
                  <label htmlFor="selectedDate">Appointment Date</label>
                  <input
                    type="date"
                    id="selectedDate"
                    value={
                      selectedDate.format
                        ? selectedDate.format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={handleDateChange}
                  />
                </p>
                <p>
                  <label htmlFor="startTime">Start</label>
                  <input
                    type="time"
                    id="startTime"
                    value={
                      startTime && selectedDate
                        ? moment(startTime).format("HH:mm")
                        : ""
                    }
                    onChange={(e) =>
                      setStartTime(
                        moment(
                          `${selectedDate.format("YYYY-MM-DD")} ${
                            e.target.value
                          }`,
                          "YYYY-MM-DD HH:mm"
                        )
                      )
                    }
                  />
                </p>
                <p>
                  <label htmlFor="endTime">End</label>
                  <input
                    type="time"
                    id="endTime"
                    value={
                      endTime && selectedDate
                        ? moment(endTime).format("HH:mm")
                        : ""
                    }
                    onChange={(e) =>
                      setEndTime(
                        moment(
                          `${selectedDate.format("YYYY-MM-DD")} ${
                            e.target.value
                          }`,
                          "YYYY-MM-DD HH:mm"
                        )
                      )
                    }
                  />
                </p>
              </div>
            )}

            <br></br>
            <button onClick={openModal}>Next</button>
          </div>

          {/* user's list modal */}
          <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
            <br />
            <br />
            <button onClick={openNewUserModal}>Add User</button>
            <h4 className="mt-4"></h4>
            <input
              type="text"
              placeholder="Search by client name"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <div className="card shadow mb-4 mt-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Clients List
                </h6>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {searchTerm ? (
                    filteredClients.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Pet Name</th>
                          </tr>
                        </thead>
                        <tbody style={{ cursor: "pointer" }}>
                          {filteredClients.map((client) => (
                            <tr
                              key={client.id}
                              className={`client-info-row ${
                                selectedClient === client
                                  ? "selected-client"
                                  : ""
                              }`}
                              onClick={() => handleClientClick(client)}
                            >
                              <td>{`${client.Prefix} ${client.FirstName} ${client.LastName}`}</td>
                              <td>
                                {client.contacts[0]?.contact_value || "N/A"}
                              </td>
                              <td>
                                {client.pets.length > 0 ? (
                                  client.pets.map((pet, index) => (
                                    <span key={index}>
                                      {pet.PetName}
                                      {index !== client.pets.length - 1
                                        ? ", "
                                        : ""}
                                    </span>
                                  ))
                                ) : (
                                  <button
                                    onClick={() => {
                                      openPetModal(client.id);
                                    }}
                                  >
                                    Add Pet
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No clients found.</p>
                    )
                  ) : (
                    <p>Please enter a search term to find clients.</p>
                  )}
                </div>
              </div>
            </div>

            {selectedClient && (
              <div className="card shadow mb-4 mt-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Currently Selected Client
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Prefix</td>
                          <td>{selectedClient.Prefix}</td>
                        </tr>
                        <tr>
                          <td>First Name</td>
                          <td>{selectedClient.FirstName}</td>
                        </tr>
                        <tr>
                          <td>Last Name</td>
                          <td>{selectedClient.LastName}</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>{selectedClient.Address}</td>
                        </tr>
                        <tr>
                          <td>Address Two</td>
                          <td>{selectedClient.AddressTwo}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        {selectedClient.contacts.map((contact, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>Contact Type</td>
                              <td>{contact.contact_type}</td>
                            </tr>
                            <tr>
                              <td>Contact Name</td>
                              <td>{contact.contact_value}</td>
                            </tr>
                            <tr>
                              <td>Contact By</td>
                              <td>{contact.contact_by}</td>
                            </tr>
                            <tr>
                              <td>Preferred Contact</td>
                              <td>{contact.preferred_contact}</td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {selectedClient && <button onClick={DogDetailModel}>Next</button>}
            <button onClick={closeModal} className="ml-2">
              Back
            </button>
          </Modal>

          {/* pets details modal */}
          <Modal isOpen={DogList} onRequestClose={closeModal}>
            <br></br>
            <br></br>
            {selectedClient && selectedClient.pets.length === 0 && (
              <div>
                <div className="row mb-4">
                  No pet is added yet, Please add pet details.
                </div>
                <div className="row">
                  <button onClick={closeModalDog} style={{ width: "100px" }}>
                    Back
                  </button>
                </div>
              </div>
            )}

            <button onClick={() => setServiceModalOpen(true)} className="mb-4">
              Add Services
            </button>

            {selectedClient && (
              <div className="selected-client-info">
                {selectedClient.contacts.map((contact, index) => (
                  <div key={index}></div>
                ))}

                {selectedClient.pets.map((pet, index) => (
                  <div key={index}>
                    <label className="mr-2">Select Pet</label>
                    <input
                      type="checkbox"
                      checked={selectedPets.includes(pet.id)}
                      onChange={() => handlePetSelection(pet.id)}
                    />
                    <div className="card shadow mb-4 mt-4">
                      <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Pet Details
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Pet Name</td>
                                <td>{pet.PetName}</td>
                              </tr>
                              <tr>
                                <td>Pet Type</td>
                                <td>{pet.PetType}</td>
                              </tr>
                              <tr>
                                <td>Gender</td>
                                <td>{pet.Gender}</td>
                              </tr>
                              <tr>
                                <td>Weight</td>
                                <td>{pet.Weight}</td>
                              </tr>
                              <tr>
                                <td>Coat Color</td>
                                <td>{pet.CoatColor}</td>
                              </tr>
                              <tr>
                                <td>Birthdate</td>
                                <td>{formatDate(pet.BirthDate)}</td>
                              </tr>
                              <tr>
                                <td>Allergies</td>
                                <td>{pet.Allergies}</td>
                              </tr>
                              <tr>
                                <td>Additional Note</td>
                                <td>{pet.AdditionalNote}</td>
                              </tr>
                              <tr>
                                <td>Vaccination Record</td>
                                <td>{pet.VaccinationRecord}</td>
                              </tr>
                              <tr>
                                <td>Date Administered</td>
                                <td>{formatDate(pet.DateAdministered)}</td>
                              </tr>
                              <tr>
                                <td>Next Due</td>
                                <td>{formatDate(pet.NextDue)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <button onClick={handleSubmit1}>Submit Booking</button>
                <button onClick={closeModalDog} className="ml-2">
                  Back
                </button>
              </div>
            )}
          </Modal>

          <Modal
            isOpen={serviceModalOpen}
            onRequestClose={() => setServiceModalOpen(false)}
            contentLabel="Service Selection"
          >
            <h3 className="mb-4">Select Services</h3>
            <input
              type="text"
              placeholder="Search for services..."
              value={serviceSearchTerm}
              onChange={(e) => setServiceSearchTerm(e.target.value)}
              className="mb-4"
            />
            <div>
              {searchResults.map((service) => (
                <div key={service.id} className="mb-4">
                  <span style={{ fontSize: "20px" }}>{service.name}</span>
                  <button
                    onClick={() => handleSelectService(service)}
                    className="ml-2"
                  >
                    Add
                  </button>
                </div>
              ))}
            </div>
            <h3></h3>
            {selectedServices.map((selectedService, index) => (
              <div key={index}>
                <span>{selectedService.service.name}</span>
                <input
                  type="number"
                  value={selectedService.quantity}
                  onChange={(e) =>
                    handleQuantityChange(
                      selectedService.service.id,
                      e.target.value
                    )
                  }
                  min="1"
                />
              </div>
            ))}
            <button onClick={() => setServiceModalOpen(false)}>Back</button>
          </Modal>

          {/* add new user modal */}
          <Modal isOpen={isNewUserModalOpen} onRequestClose={closeNewUserModal}>
            <br></br>
            <br></br>
            <div className="container">
              <h2>Add New Client</h2>
              <div>
                <br></br>
                <br></br>
                <div className="row mb-3">
                  {/*}   <div className="col-md-6">
                   <Form.Group controlId="prefix">
                      <Form.Label className="">Prefix</Form.Label>
                      <Form.Control
                        as="select"
                        value={clientData.Prefix}
                        onChange={(e) =>
                          handleClientChange("Prefix", e.target.value)
                        }
                      >
                        <option value="">Select</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Mrs.">Mrs.</option>
                      </Form.Control>
                      </Form.Group> 
                  </div>*/}
                  <div className="col-md-6">
                    <Form.Label className="">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      value={clientData.FirstName}
                      onChange={(e) =>
                        handleClientChange("FirstName", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      value={clientData.LastName}
                      onChange={(e) =>
                        handleClientChange("LastName", e.target.value)
                      }
                    />
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className="row mb-3">
                  {/*}    <div className="col-md-6">
                    <Form.Label className="">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      value={clientData.LastName}
                      onChange={(e) =>
                        handleClientChange("LastName", e.target.value)
                      }
                    />
                    </div> */}
                  <div className="col-md-6">
                    <Form.Group controlId="prefix">
                      <Form.Label className="">Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address"
                        value={clientData.Address}
                        onChange={(e) =>
                          handleClientChange("Address", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Address Two</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address Two"
                      value={clientData.AddressTwo}
                      onChange={(e) =>
                        handleClientChange("AddressTwo", e.target.value)
                      }
                    />
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Form.Group controlId="prefix">
                      <Form.Label className="">Postal Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Postal Code"
                        value={clientData.PostalCode}
                        onChange={(e) =>
                          handleClientChange("PostalCode", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      value={clientData.City}
                      onChange={(e) =>
                        handleClientChange("City", e.target.value)
                      }
                    />
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Form.Label className="">State/Country</Form.Label>

                    <Form.Select
                      name="StateCountry"
                      value={clientData.StateCountry}
                      onChange={(e) =>
                        handleClientChange("StateCountry", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Aguascalientes">Aguascalientes</option>
                      <option value="Baja California">Baja California</option>
                      <option value="Baja California Sur">
                        Baja California Sur
                      </option>
                      <option value="Campeche">Campeche</option>
                      <option value="Chiapas">Chiapas</option>
                      <option value="Chihuahua">Chihuahua</option>
                      <option value="Ciudad de México">Ciudad de México</option>
                      <option value="Coahuila">Coahuila</option>
                      <option value="Colima">Colima</option>
                      <option value="Durango">Durango</option>
                      <option value="Guanajuato">Guanajuato</option>
                      <option value="Guerrero">Guerrero</option>
                      <option value="Hidalgo">Hidalgo</option>
                      <option value="Jalisco">Jalisco</option>
                      <option value="México">México</option>
                      <option value="Michoacán">Michoacán</option>
                      <option value="Morelos">Morelos</option>
                      <option value="Nayarit">Nayarit</option>
                      <option value="Nuevo León">Nuevo León</option>
                      <option value="Oaxaca">Oaxaca</option>
                      <option value="Puebla">Puebla</option>
                      <option value="Querétaro">Querétaro</option>
                      <option value="Quintana Roo">Quintana Roo</option>
                      <option value="San Luis Potosí">San Luis Potosí</option>
                      <option value="Sinaloa">Sinaloa</option>
                      <option value="Sonora">Sonora</option>
                      <option value="Tabasco">Tabasco</option>
                      <option value="Tamaulipas">Tamaulipas</option>
                      <option value="Tlaxcala">Tlaxcala</option>
                      <option value="Veracruz">Veracruz</option>
                      <option value="Yucatán">Yucatán</option>
                      <option value="Zacatecas">Zacatecas</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Area</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Area"
                      value={clientData.Area}
                      onChange={(e) =>
                        handleClientChange("Area", e.target.value)
                      }
                    />
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Form.Label className="">Client Referral Source</Form.Label>
                    <Form.Select
                      name="ClientRefferalSource"
                      value={clientData.ClientRefferalSource}
                      onChange={(e) =>
                        handleClientChange(
                          "ClientRefferalSource",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select a source</option>
                      <option value="Search Results - Google">
                        Search Results - Google
                      </option>
                      <option value="Search Results - Bing">
                        Search Results - Bing
                      </option>
                      <option value="Search Results - Yahoo">
                        Search Results - Yahoo
                      </option>
                      <option value="Social - Facebook">
                        Social - Facebook
                      </option>
                      <option value="Social - Twitter">Social - Twitter</option>
                      <option value="Social - Instagram">
                        Social - Instagram
                      </option>
                      <option value="Referral - Friend or Family">
                        Referral - Friend or Family
                      </option>
                      <option value="Referral - Groomer">
                        Referral - Groomer
                      </option>
                      <option value="Referral - Pet Store">
                        Referral - Pet Store
                      </option>
                      <option value="Referral - Kennel">
                        Referral - Kennel
                      </option>
                      <option value="Referral - Veterinarian">
                        Referral - Veterinarian
                      </option>
                      <option value="Returning Client">Returning Client</option>
                      <option value="Saw Sign - Drive by ">
                        Saw Sign - Drive by{" "}
                      </option>
                      <option value="Advertisement - Online / Digital">
                        Advertisement - Online / Digital
                      </option>
                      <option value="Advertisement - Print">
                        Advertisement - Print
                      </option>
                      <option value="Advertisement - Radio">
                        Advertisement - Radio
                      </option>
                      <option value="Walk In">Walk In</option>
                      <option value="Groupon">Groupon</option>
                      <option value="ValPack">ValPack</option>
                      <option value="Website">Website</option>
                      <option value="Yelp">Yelp</option>
                      <option value="Other Source - Not Listed">
                        Other Source - Not Listed
                      </option>
                    </Form.Select>
                  </div>
                  <div className="col-md-6">
                    <Form.Group controlId="prefix">
                      <Form.Label className="">Client Since</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Client Since"
                        value={clientData.ClientSince}
                        onChange={(e) =>
                          handleClientChange("ClientSince", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <Form.Label className="mt-4">Special Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={clientData.SpecialNotes}
                  onChange={(e) =>
                    handleClientChange("SpecialNotes", e.target.value)
                  }
                />
                <br></br>
                <h3 className="mt-4 mb-4">Contact Info</h3>
                {clientData.contacts.map((contact, index) => (
                  <div key={index}>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <Form.Group controlId="prefix">
                          <Form.Label>Contact Type</Form.Label>
                          <Form.Control
                            as="select"
                            value={contact.contact_type}
                            onChange={(e) =>
                              handleContactChange(
                                index,
                                "contact_type",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select</option>
                            <option value="Home">Home</option>
                            <option value="Mobile">Mobile</option>
                            <option value="Email">Email</option>
                            <option value="Work">Work</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        {contact.contact_type === "Mobile" ? (
                          <div>
                            <Form.Label>Contact Number</Form.Label>
                            <PhoneInput
                              international
                              defaultCountry="MX"
                              value={contact.contact_value}
                              onChange={(value) =>
                                handleContactChange(
                                  index,
                                  "contact_value",
                                  value
                                )
                              }
                            />
                          </div>
                        ) : (
                          <div>
                            <Form.Label>Contact</Form.Label>
                            <input
                              type="text"
                              name="contact_value"
                              value={contact.contact_value}
                              onChange={(e) =>
                                handleContactChange(
                                  index,
                                  "contact_value",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <Form.Check
                      type="checkbox"
                      label="Set as Primary"
                      checked={contact.primary}
                      onChange={(e) =>
                        handleContactChange(index, "primary", e.target.checked)
                      }
                    />
                    &nbsp;&nbsp;&nbsp;
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <Form.Label>Contact By</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Contact By"
                          value={contact.contact_by}
                          onChange={(e) =>
                            handleContactChange(
                              index,
                              "contact_by",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Label>Preferred Contact</Form.Label>
                        <Form.Control
                          as="select"
                          value={contact.preferred_contact}
                          onChange={(e) =>
                            handleContactChange(
                              index,
                              "preferred_contact",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          <option value="sms">SMS/Text</option>
                          <option value="email">Email</option>
                          <option value="phone">Phone</option>
                        </Form.Control>
                      </div>
                    </div>
                  </div>
                ))}
                <br></br>
                <button onClick={addContact}>Add Contact Form</button>
                <br></br>
                <br></br>
                <button onClick={handleSubmit}>Submit</button>
                <br></br>
                <br></br>
              </div>
              <button onClick={closeNewUserModal}>Back</button>
            </div>
            <br></br>
          </Modal>
          <Modal isOpen={isPetModalOpen} onRequestClose={closePetModal}>
            <br></br>
            <br></br>
            <div className="container">
              <h2>Add New Pet</h2>
              <br></br>
              <br></br>
              <form onSubmit={handleSubmitq} style={{ background: "white" }}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Form.Group controlId="prefix">
                      <Form.Label className="">Pet Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="PetName"
                        placeholder="Pet Name"
                        value={petData.PetName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Pet Type</Form.Label>
                    <Form.Select
                      name="PetType"
                      value={petData.PetType}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="Dog">Dog</option>
                      <option value="Cat">Cat</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Form.Label className="">Gender</Form.Label>
                    <Form.Select
                      name="Gender"
                      value={petData.Gender}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Breed</Form.Label>
                    <Form.Control
                      type="text"
                      name="Breed"
                      placeholder="Breed"
                      value={petData.Breed}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {breedSuggestions.length > 0 && (
                      <ul
                        style={{
                          listStyleType: "none",
                          padding: 0,
                          position: "absolute",
                          backgroundColor: "white",
                          zIndex: 1000,
                        }}
                      >
                        {breedSuggestions.map((breed, index) => (
                          <li
                            key={index}
                            onClick={() => handleSelectBreed(breed)}
                            style={{ cursor: "pointer", padding: "5px" }}
                          >
                            {breed}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <br></br>
                <div className="row mb-3">
                  <div className="col-md-6 py-2 px-4">
                    <Form.Group controlId="prefix">
                      <input
                        type="checkbox"
                        name="Spayed"
                        checked={petData.Spayed}
                        onChange={(e) =>
                          setPetData({ ...petData, Spayed: e.target.checked })
                        }
                        className="form-check-input"
                      />
                      <label className="form-check-label ml-2">
                        Spayed / Neutered
                      </label>
                    </Form.Group>
                  </div>
                </div>
                <br></br>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Form.Group controlId="prefix">
                      <Form.Label className="">Weight</Form.Label>
                      <Form.Control
                        type="text"
                        name="Weight"
                        placeholder="Weight"
                        value={petData.Weight}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Coat Color</Form.Label>
                    <Form.Control
                      type="text"
                      name="CoatColor"
                      placeholder="Coat Color"
                      value={petData.CoatColor}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Form.Label className="">Birth Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="BirthDate"
                      placeholder="Birth Date"
                      value={petData.BirthDate}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Allergies</Form.Label>
                    <Form.Group controlId="prefix">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        maxLength={200}
                        name="Allergies"
                        value={petData.Allergies}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Form.Label className="">Additional Note</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      maxLength={200}
                      name="AdditionalNote"
                      value={petData.AdditionalNote}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Vaccination Record</Form.Label>

                    <Form.Select
                      name="VaccinationRecord"
                      value={petData.VaccinationRecord}
                      onChange={handleChange}
                    >
                      <option value="">Select Type</option>
                      <option value="Rabies">Rabies</option>
                      <option value="Bordetella">Bordetella</option>
                      <option value="Canine Distemper / Parvo Combo">
                        Canine Distemper / Parvo Combo
                      </option>
                      <option value="Canine Influenza">Canine Influenza</option>
                      <option value="Leptospirosis (Lepto4)">
                        Leptospirosis (Lepto4)
                      </option>
                      <option value="Giardia">Giardia</option>
                      <option value="Canine C3 (dist, hep, parvo)">
                        Canine C3 (dist, hep, parvo)
                      </option>
                      <option value="Canine C4 (dist, parainfluenza)">
                        Canine C4 (dist, parainfluenza)
                      </option>
                      <option value="Canine C5 (dist, hep, parvo, borda, parainfluenza)">
                        Canine C5 (dist, hep, parvo, borda, parainfluenza)
                      </option>
                      <option value="Canine C7 (dist, hep, parvo, borda, para, lept, tet)">
                        Canine C7 (dist, hep, parvo, borda, para, lept, tet)
                      </option>
                      <option value="Feline FVRCP group (rhino, calic, distemper)">
                        Feline FVRCP group (rhino, calic, distemper)
                      </option>
                      <option value="Feline Herpesvirus (FHV)">
                        Feline Herpesvirus (FHV)
                      </option>
                      <option value="Feline Calicivirus (FCV)">
                        Feline Calicivirus (FCV)
                      </option>
                      <option value="Feline Leukemia (FeLV)">
                        Feline Leukemia (FeLV)
                      </option>
                      <option value="Feline Panleukopenia (FPK)">
                        Feline Panleukopenia (FPK)
                      </option>
                    </Form.Select>
                  </div>
                </div>
                <br></br>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Form.Label className="">Date Administered</Form.Label>
                    <Form.Group controlId="prefix">
                      <Form.Control
                        type="date"
                        name="DateAdministered"
                        placeholder="Date Administered"
                        value={petData.DateAdministered}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Next Due</Form.Label>
                    <Form.Control
                      type="date"
                      name="NextDue"
                      placeholder="Next Due"
                      value={petData.NextDue}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <br></br>
                {/* You can handle MultiSelectField as dropdowns or checkboxes */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Health Flags (optional)</label>
                    {renderFlagCheckboxes("health")}
                  </div>
                  <div className="col-md-6">
                    <label>Services Flags (optional)</label>
                    {renderFlagCheckboxes("service")}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <label>Guest Flags (optional)</label>
                    {renderFlagCheckboxes("guest")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {/* File upload field */}
                    <Form.Group controlId="prefix">
                      <Form.Label>Pet Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="PetImage"
                        onChange={(e) =>
                          setPetData({
                            ...petData,
                            PetImage: e.target.files[0],
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="">Instagram/Tiktok URL</Form.Label>
                    <Form.Control
                      type="url"
                      name="social_url"
                      placeholder="Instagram/Tiktok"
                      value={petData.social_url}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <button type="submit" className="mt-4">
                  Submit
                </button>
                <button onClick={closePetModal} className="ml-2">
                  back
                </button>
              </form>
            </div>
          </Modal>

          {/* Review Booking Modal */}
          <Modal
            isOpen={reviewModalOpen}
            onRequestClose={() => setReviewModalOpen(false)}
          >
            <h3 className="mb-4">Review Appointment</h3>
            <table class="table">
              <tbody>
                <tr>
                  <th>Date:</th>
                  <td>{selectedDate?.format("YYYY-MM-DD")}</td>
                </tr>
                <tr>
                  <th>Time:</th>
                  <td>
                    {startTime?.format("HH:mm")} to {endTime?.format("HH:mm")}
                  </td>
                </tr>
                <tr>
                  <th>Client:</th>
                  <td>{selectedClient?.FirstName}</td>
                </tr>
                {/*}   <tr>
                  <th></th>
                  <td>
                    <ul>
                      {selectedServices.map((service, index) => (
                        <li key={index}>
                          {service.name}: {service.quantity}
                        </li>
                      ))}
                    </ul>
                  </td>
                      </tr> */}
              </tbody>
            </table>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Additional Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                maxLength={200}
                name="additional_details"
                onChange={handleAdditonalNotesChange}
              />
            </Form.Group>

            {/* Action buttons */}
            <div style={{ marginTop: "20px" }}>
              <button
                onClick={() => setReviewModalOpen(false)}
                style={{ marginRight: "10px" }}
              >
                Edit
              </button>
              <button onClick={finalizeBooking}>Confirm</button>
            </div>
          </Modal>
        </Container>

        <BootstrapModal
          show={showBootstrapModal}
          onHide={handleCloseBootstrapModal}
          dialogClassName="custom-modal-width"
        >
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Booking Detail</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            {!selectedBookingId ? (
              <div>No booking selected</div>
            ) : (
              <ShopBookingsDetail
                shopName={shopName}
                id={selectedBookingId}
                bookingIds={selectedBookingIds}
              />
            )}
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <Button variant="secondary" onClick={handleCloseBootstrapModal}>
              Close
            </Button>
          </BootstrapModal.Footer>
        </BootstrapModal>
      </div>
    </div>
  );
};

export default Booking;
