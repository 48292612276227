import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import ClockInOut from "./../Attendance/ClockInOut.js";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [appointmentDropdownOpen, setAppointmentDropdownOpen] = useState(false);
  const [reservationDropdownOpen, setReservationDropdownOpen] = useState(false);
  const [clientCenterDropdownOpen, setClientCenterDropdownOpen] = useState(false);
  const [inventoryDropdownOpen, setInventoryDropdownOpen] = useState(false);
  const [managerDropdownOpen, setManagerDropdownOpen] = useState(false);
  const [employeeDropdownOpen, setEmployeeDropdownOpen] = useState(false);
  const [petsCatalogsDropdownOpen, setPetsCatalogsDropdownOpen] = useState(false);

  return (
    <div>
      <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion">
        <li className="sidebar-brand d-flex align-items-center justify-content-center">
          <Link to="/" className="sidebar-brand-text mx-3 text-decoration-none text-white">
            Logo
          </Link>
        </li>
        <hr className="sidebar-divider my-0" />

        <li className="nav-item active">
          <Link to="/shop" className="nav-link text-decoration-none text-white">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Shop Detail
          </Link>
        </li>

        <li className="nav-item active">
          <Link to="/shop-bookings" className="nav-link text-decoration-none text-white">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Today's Booking
          </Link>
        </li>

        <li className="nav-item dropdown active">
          <button
            className="nav-link btn btn-link text-white"
            onClick={() => setAppointmentDropdownOpen(!appointmentDropdownOpen)}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Appointment
            <FontAwesomeIcon
              icon={appointmentDropdownOpen ? faAngleUp : faAngleDown}
              className="ml-2"
            />
          </button>
          {appointmentDropdownOpen && (
            <div className="dropdown-menu show">
              <Link to="/booking" className="dropdown-item">
                Appointment
              </Link>
              <Link to="/appointment-bookings" className="dropdown-item">
                Appointment Calendar
              </Link>
              <Link to="/calendars-list" className="dropdown-item">
                Calendar
              </Link>
            </div>
          )}
        </li>

        <li className="nav-item dropdown active">
          <button
            className="nav-link btn btn-link text-white"
            onClick={() => setReservationDropdownOpen(!reservationDropdownOpen)}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Reservation
            <FontAwesomeIcon
              icon={reservationDropdownOpen ? faAngleUp : faAngleDown}
              className="ml-2"
            />
          </button>
          {reservationDropdownOpen && (
            <div className="dropdown-menu show">
              <Link to="/reservation" className="dropdown-item">
                Reservation
              </Link>
              <Link to="/reservation-bookings" className="dropdown-item">
                Reservation Calendar
              </Link>
            </div>
          )}
        </li>

        <li className="nav-item dropdown active">
          <button
            className="nav-link btn btn-link text-white"
            onClick={() => setClientCenterDropdownOpen(!clientCenterDropdownOpen)}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Client Center
            <FontAwesomeIcon
              icon={clientCenterDropdownOpen ? faAngleUp : faAngleDown}
              className="ml-2"
            />
          </button>
          {clientCenterDropdownOpen && (
            <div className="dropdown-menu show">
              <Link to="/shop-clients" className="dropdown-item">
                Client Center
              </Link>
              <Link to="/shop-add-clients" className="dropdown-item">
                Add New Client
              </Link>
              <Link to="/client-leads" className="dropdown-item">
                Client Leads
              </Link>
              <Link to="/inactive-clients" className="dropdown-item">
                Inactive Clients
              </Link>
            </div>
          )}
        </li>

        <li className="nav-item dropdown active">
          <button
            className="nav-link btn btn-link text-white"
            onClick={() => setInventoryDropdownOpen(!inventoryDropdownOpen)}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Inventory
            <FontAwesomeIcon
              icon={inventoryDropdownOpen ? faAngleUp : faAngleDown}
              className="ml-2"
            />
          </button>
          {inventoryDropdownOpen && (
            <div className="dropdown-menu show">
              <Link to="/inventory-main" className="dropdown-item">
                Inventory Main
              </Link>
              <Link to="/item-categories" className="dropdown-item">
                Item Categories
              </Link>
              <Link to="/reorder-items" className="dropdown-item">
                Reorder Items
              </Link>
              <Link to="/prepaid-packages" className="dropdown-item">
                Prepaid Packages
              </Link>
              <Link to="/vendors" className="dropdown-item">
                Vendors
              </Link>
              <Link to="/services" className="dropdown-item">
                Services
              </Link>
              <Link to="/purchase-order" className="dropdown-item">
                Purchase Orders
              </Link>
            </div>
          )}
        </li>

        <li className="nav-item active">
          <Link to="/point-of-sale" className="nav-link text-decoration-none text-white">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Point of Sale
          </Link>
        </li>

        {userInfo?.user_role === "Manager" && (
          <li className="nav-item dropdown active">
            <button
              className="nav-link btn btn-link text-white"
              onClick={() => setManagerDropdownOpen(!managerDropdownOpen)}
            >
              <FontAwesomeIcon icon={faChartBar} className="mr-4" />
              Manager
              <FontAwesomeIcon
                icon={managerDropdownOpen ? faAngleUp : faAngleDown}
                className="ml-2"
              />
            </button>
            {managerDropdownOpen && (
              <div className="dropdown-menu show">
                <Link to={`/`} className="dropdown-item">
                  Switch Stores
                </Link>
                <Link to={`/shops-list`} className="dropdown-item">
                  Shops
                </Link>
                <Link to={`/banks`} className="dropdown-item">
                  Banks
                </Link>
                <Link to={`/banks-accounts`} className="dropdown-item">
                  Bank Accounts
                </Link>
                <Link to={`/bank-reconciliation`} className="dropdown-item">
                  Bank Reconciliation
                </Link>
                <Link to={`/bank-transfers`} className="dropdown-item">
                  Bank Transfers
                </Link>
                <Link to={`/cash-reconciliation`} className="dropdown-item">
                  Cash Register
                </Link>
                <Link to={`/promotions`} className="dropdown-item">
                  Promotions
                </Link>
                <Link to={`/reports`} className="dropdown-item">
                  Reports
                </Link>
                <Link to={`/expense-form`} className="dropdown-item">
                  Expense Form
                </Link>
                <Link to={`/sales-dashboard`} className="dropdown-item">
                  Sales Dashboard
                </Link>
                <Link to={`/financial-dashboard`} className="dropdown-item">
                  Financial Dashboard
                </Link>
                <Link to={`/retail-dashboard`} className="dropdown-item">
                  Retail Dashboard
                </Link>
              </div>
            )}
          </li>
        )}

        <li className="nav-item dropdown active">
          <button
            className="nav-link btn btn-link text-white"
            onClick={() => setPetsCatalogsDropdownOpen(!petsCatalogsDropdownOpen)}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Pets Catalogs
            <FontAwesomeIcon
              icon={petsCatalogsDropdownOpen ? faAngleUp : faAngleDown}
              className="ml-2"
            />
          </button>
          {petsCatalogsDropdownOpen && (
            <div className="dropdown-menu show">
              <Link to="/breed-list" className="dropdown-item">
                Breeds List
              </Link>
              <Link to="/add-breed-list" className="dropdown-item">
                Add Breeds
              </Link>
              <Link to="/flags-list" className="dropdown-item">
                Flags List
              </Link>
            </div>
          )}
        </li>

        <li className="nav-item dropdown active">
          <button
            className="nav-link btn btn-link text-white"
            onClick={() => setEmployeeDropdownOpen(!employeeDropdownOpen)}
          >
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Employees
            <FontAwesomeIcon
              icon={employeeDropdownOpen ? faAngleUp : faAngleDown}
              className="ml-2"
            />
          </button>
          {employeeDropdownOpen && (
            <div className="dropdown-menu show">
              {userInfo?.user_role === "Manager" && (
                <Link to="/employee-payroll" className="dropdown-item">
                  Employee Payroll
                </Link>
              )}
              <Link to="/employee-center" className="dropdown-item">
                Employee Center
              </Link>
              <Link to="/employees" className="dropdown-item">
                Add Employee
              </Link>
              <Link to="/my-profile" className="dropdown-item">
                My Profile
              </Link>
              <Link onClick={handleOpenModal} className="dropdown-item">
                Clock In/Out
              </Link>
            </div>
          )}
        </li>

        <li className="nav-item active">
          <Link to="/message-screen" className="nav-link text-decoration-none text-white">
            <FontAwesomeIcon icon={faChartBar} className="mr-4" />
            Messaging
          </Link>
        </li>
      </ul>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Employee Time Clock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClockInOut />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
