import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";

const apiUrl = process.env.REACT_APP_BASE_URL;

const BankAccountForm = ({ selectedBankAccount, onSave }) => {
  const [stores, setStores] = useState([]);
  const [banks, setBanks] = useState([]);
  const [bankAccount, setBankAccount] = useState({
    stores: [],
    account_number: "",
    bank: "",
    account_type: "debit",
  });

  useEffect(() => {
    axios.get(`${apiUrl}inventory/stores/`).then((response) => {
      setStores(response.data);
    });

    axios.get(`${apiUrl}inventory/banks/`).then((response) => {
      setBanks(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedBankAccount) {
      // Prepopulate the form with the selected bank account details
      setBankAccount({
        ...selectedBankAccount,
        stores: selectedBankAccount.stores || [], // Ensure stores is an array
      });
    }
  }, [selectedBankAccount]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBankAccount((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (storeId) => {
    setBankAccount((prev) => {
      const isSelected = prev.stores.includes(storeId);
      return {
        ...prev,
        stores: isSelected
          ? prev.stores.filter((id) => id !== storeId) // Remove store
          : [...prev.stores, storeId], // Add store
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { ...bankAccount };

    if (bankAccount.id) {
      axios
        .put(`${apiUrl}inventory/bankaccounts/${bankAccount.id}/`, payload)
        .then(() => onSave());
    } else {
      axios
        .post(`${apiUrl}inventory/bankaccounts/`, bankAccount)
        .then(() => onSave());
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Store</Form.Label>
        <div>
          {stores.map((store) => (
            <Form.Check
              key={store.id}
              type="checkbox"
              label={store.ShopName}
              value={store.id}
              checked={bankAccount.stores.includes(store.id)}
              onChange={() => handleCheckboxChange(store.id)}
            />
          ))}
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Account Number</Form.Label>
        <Form.Control
          type="text"
          name="account_number"
          value={bankAccount.account_number}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Bank</Form.Label>
        <Form.Control
          as="select"
          name="bank"
          value={bankAccount.bank}
          onChange={handleInputChange}
        >
          <option value="">Select Bank</option>
          {banks.map((bank) => (
            <option key={bank.id} value={bank.id}>
              {bank.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>Account Type</Form.Label>
        <Form.Control
          as="select"
          name="account_type"
          value={bankAccount.account_type || "debit"}
          onChange={handleInputChange}
        >
          <option value="debit">Debit</option>
          <option value="credit">Credit</option>
          <option value="savings">Savings</option>
        </Form.Control>
      </Form.Group>
      <Button variant="primary" type="submit">
        Save
      </Button>
    </Form>
  );
};

export default BankAccountForm;
