import React, { useEffect, useState } from "react";
import { Table, Button, Dropdown, DropdownButton } from "react-bootstrap";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import "./InactiveClients.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";


const InactiveClients = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("1 month");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Number of rows per page
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    axios
      .get(`${apiUrl}api/inactive-clients/?filter=${filter}`)
      .then((response) => {
        console.log(response.data);
        setUsers(response.data);
        setTotalPages(Math.ceil(response.data.length / rowsPerPage));
      })
      .catch((error) => {
        console.error("There was an error fetching the users!", error);
      });
  }, [filter, apiUrl]);

  const createReminder = (clientId) => {
    axios
      .post(`${apiUrl}api/create-reminder/`, { client_id: clientId })
      .then(() => {
        alert("Reminder created successfully");

        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === clientId
              ? {
                  ...user,
                  last_reminder_date: new Date().toISOString().split("T")[0],
                }
              : user
          )
        );
      })
      .catch((error) => {
        console.error("There was an error creating the reminder!", error);
        alert("Failed to create reminder");
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Pagination
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentUsers = users.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Inactive Clients
        </h3>
        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <DropdownButton
            className="mb-4"
            id="dropdown-basic-button"
            title={`Filter by time period`}
            onSelect={(eventKey) => setFilter(eventKey)}
          >
            <Dropdown.Item eventKey="1 month">1 Month</Dropdown.Item>
            <Dropdown.Item eventKey="2 months">2 Months</Dropdown.Item>
            <Dropdown.Item eventKey="3 months">3 Months</Dropdown.Item>
          </DropdownButton>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Owner</th>
                <th>Pet Name</th>
                <th>Last Booking</th>
                <th>Last Reminder</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user) => (
                <tr key={user.id}>
                  <td>
                    {user.first_name} {user.last_name}
                    {user.status === "warning" || user.status === "caution" ? (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        style={{ color: "orange", marginLeft: "8px" }}
                        title={user.status} // Tooltip for accessibility
                      />
                    ) : null}
                  </td>
                  <td>{Array.isArray(user.pet_names) ? user.pet_names.join(", ") : user.pet_names}</td>
                  <td>{formatDate(user.last_booking_date)}</td>
                  <td>{user.last_reminder_date ? formatDate(user.last_reminder_date) : "-"}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => createReminder(user.id)}
                    >
                      Remind
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* Pagination Controls */}
          <div className="pagination">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InactiveClients;
