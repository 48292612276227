import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Modal, Button, Form } from "react-bootstrap";
import Sidebar from "../Design/Sidebar.js";

const apiUrl = process.env.REACT_APP_BASE_URL;

const BankTransfers = () => {
  const [transfers, setTransfers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newTransfer, setNewTransfer] = useState({
    source_account: "",
    destination_account: "",
    store: "",
    amount: "",
    transfer_date: "",
    description: "",
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const [transfersData, accountsData, storesData] = await Promise.all([
        axios.get(`${apiUrl}inventory/bank-transfers/`),
        axios.get(`${apiUrl}inventory/bankaccounts/`),
        axios.get(`${apiUrl}inventory/stores/`),
      ]);
      setTransfers(transfersData.data);
      setAccounts(accountsData.data);
      setStores(storesData.data);
    } catch (err) {
      setError("Failed to load data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTransfer({ ...newTransfer, [name]: value });
  };

  const validateTransfer = () => {
    if (newTransfer.amount <= 0) {
      return "Amount must be greater than zero.";
    }
    if (
      newTransfer.source_account &&
      newTransfer.source_account === newTransfer.destination_account
    ) {
      return "Source and destination accounts cannot be the same.";
    }
    return null;
  };

  const handleSubmit = async () => {
    const validationError = validateTransfer();
    if (validationError) {
      alert(validationError);
      return;
    }

    const payload = {
      source_account: newTransfer.source_account.startsWith("store-")
        ? newTransfer.source_account.split("-")[1]
        : newTransfer.source_account,
      destination_account: newTransfer.destination_account.startsWith("store-")
        ? newTransfer.destination_account.split("-")[1]
        : newTransfer.destination_account,
      amount: parseFloat(newTransfer.amount).toFixed(2),
      transfer_date: newTransfer.transfer_date,
      description: newTransfer.description,
    };

    try {
      setLoading(true);
      await axios.post(`${apiUrl}inventory/bank-transfers/`, payload);
      setShowModal(false);
      fetchInitialData(); // Refresh data
    } catch (err) {
      alert("Failed to save transfer. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formatAccountNumber = (accountNumber) => {
    return `****${accountNumber.slice(-4)}`;
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <h3>Bank Transfers</h3>
        <Button onClick={() => setShowModal(true)}>Add Transfer</Button>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Source</th>
                <th>Destination</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Description</th>
            </tr>
            </thead>
            <tbody>
            {transfers.map((transfer) => (
                <tr key={transfer.id}>
                <td>{transfer.source_account_name || "Cash"}</td>
                <td>{transfer.destination_account_name}</td>
                <td>{transfer.amount}</td>
                <td>{transfer.transfer_date}</td>
                <td>{transfer.description}</td>
                </tr>
            ))}
            </tbody>
        </Table>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Add Bank Transfer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
                <Form.Group>
                <Form.Label>Source Account</Form.Label>
                <Form.Control
                    as="select"
                    name="source_account"
                    value={newTransfer.source_account}
                    onChange={handleInputChange}
                >
                    <option value="">Select Source</option>
                    {stores.map((store) => (
                        <option key={`store-${store.id}`} value={`store-${store.id}`}>
                        Store: {store.ShopName}
                        </option>
                    ))}
                    {accounts.map((account) => (
                        <option key={`account-${account.id}`} value={`account-${account.id}`}>
                        {account.bank_name} - {formatAccountNumber(account.account_number)} - {account.account_type}
                        </option>
                    ))}
                </Form.Control>
                </Form.Group>
                <Form.Group>
                <Form.Label>Destination Account</Form.Label>
                <Form.Control
                    as="select"
                    name="destination_account"
                    value={newTransfer.destination_account}
                    onChange={handleInputChange}
                >
                    <option value="">Select Destination</option>
                    {stores.map((store) => (
                        <option key={`store-${store.id}`} value={`store-${store.id}`}>
                        Store: {store.ShopName}
                        </option>
                    ))}
                    {accounts.map((account) => (
                        <option key={`account-${account.id}`} value={`account-${account.id}`}>
                        {account.bank_name} - {formatAccountNumber(account.account_number)} - {account.account_type}
                        </option>
                    ))}
                </Form.Control>
                </Form.Group>
                <Form.Group>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                    type="number"
                    name="amount"
                    value={newTransfer.amount}
                    onChange={handleInputChange}
                    min="0.01"
                    step="0.01"
                />
                </Form.Group>
                <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control
                    type="date"
                    name="transfer_date"
                    value={newTransfer.transfer_date}
                    onChange={handleInputChange}
                />
                </Form.Group>
                <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    name="description"
                    value={newTransfer.description}
                    onChange={handleInputChange}
                    rows={3}
                />
                </Form.Group>
                <Button variant="primary" onClick={handleSubmit}>
                Save Transfer
                </Button>
            </Form>
            </Modal.Body>
        </Modal>
      </div>

    </div>
  );
};

export default BankTransfers;