import React, { useState, useEffect } from "react";
import axios from "axios";
import BankAccountForm from "./BankAccountForm";
import { Table, Modal, Button } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";
import { useSelector, useDispatch } from "react-redux";

const apiUrl = process.env.REACT_APP_BASE_URL;

const BankAccounts = () => {
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;

  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBankAccount(null);
  };

  useEffect(() => {
    loadBankAccounts();
  }, []);

  const loadBankAccounts = () => {
    axios.get(`${apiUrl}inventory/bankaccounts/`).then((response) => {
      setBankAccounts(response.data);
    });
  };

  const handleEdit = (bankAccount) => {
    setSelectedBankAccount(bankAccount);
    handleOpenModal();
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this bank account?")) {
      axios
        .delete(`${apiUrl}inventory/bankaccounts/${id}/`)
        .then(() => loadBankAccounts());
    }
  };

  const handleSave = () => {
    setSelectedBankAccount(null);
    loadBankAccounts();
    handleCloseModal();
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar id={id} shopName={shopName} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Bank Accounts
        </h3>

        <div>
          <Button onClick={() => handleOpenModal()} className="mt-2 mb-4 mr-2">
            Add Bank Account
          </Button>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Store</th>
                <th>Account Number</th>
                <th>Bank Name</th>
                <th>Account Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {bankAccounts.map((bankAccount) => (
                <tr key={bankAccount.id}>
                  <td className="text-center">{bankAccount.store_names}</td>
                  <td className="text-center">{bankAccount.account_number}</td>
                  <td className="text-center">{bankAccount.bank_name}</td>
                  <td className="text-center">{bankAccount.account_type}</td>
                  <td className="text-center">
                    <button
                      onClick={() => handleEdit(bankAccount)}
                      className="mr-2"
                    >
                      Edit
                    </button>
                    <button onClick={() => handleDelete(bankAccount.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            dialogClassName="custom-modal-width"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedBankAccount ? "Edit Bank Account" : "Add Bank Account"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BankAccountForm
                selectedBankAccount={selectedBankAccount}
                onSave={handleSave}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default BankAccounts;
