import React from "react";
import { Table } from "react-bootstrap";

const CommissionDetails = ({ commissions }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name of the Pet</th>
          <th>Service Provided</th>
          <th>Service Price</th>
          <th>Commission</th>
        </tr>
      </thead>
      <tbody>
        {commissions.map((commission, index) => (
          <tr key={commission.id || `${commission.booking__petname__PetName}-${index}`}>
            <td>{commission.booking__petname__PetName || "N/A"}</td>
            <td>{commission.service__name || "N/A"}</td>
            <td>${commission.total_earnings}</td>
            <td>${commission.commission}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CommissionDetails;
