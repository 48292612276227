import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import "./Header.css";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { logout } from ".././actions/userActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faCalendar, faHome } from "@fortawesome/free-solid-svg-icons";

function Header({ toggleSidebar }) {
  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);
  const shop = useSelector((state) => state.shop)
  const { userInfo } = userLogin;
  const { shopName } = shop;

  /* HANDLER */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/login");
  };

  useEffect(() => {
    if (!userInfo) navigate('/login');
  }, [userInfo, navigate]);

  const userRoles = {
    Manager: "Manager",
    "Assistant Manager": "Assistant Manager",
    Groomer: "Groomer",
    Daycare: "Daycare",
    Reception: "Reception",
  };

  return (
    <header className="app-header">
      <div className="header-container">
        <div className="left-section">
        <span className="shop-name">Current Store: </span>{shopName && <span className="shop-name">{shopName}</span>}
        </div>
        <div className="quick-menu">
          <Link to="/point-of-sale" className="quick-menu-item" title="Point of Sale">
            <FontAwesomeIcon icon={faShoppingCart} className="quick-menu-icon" />
            <span>POS</span>
          </Link>
          <Link to="/booking" className="quick-menu-item" title="Appointments">
            <FontAwesomeIcon icon={faCalendar} className="quick-menu-icon" />
            <span>Appointments</span>
          </Link>
          <Link to="/reservation-bookings" className="quick-menu-item" title="Reservations">
            <FontAwesomeIcon icon={faHome} className="quick-menu-icon" />
            <span>Reservations</span>
          </Link>
        </div>
        <div className="right-section">
          {userInfo ? (
            <>
              <span className="user-info">
                {userInfo.username} ({userRoles[userInfo.user_role]})
              </span>
              <span className="logout" onClick={logoutHandler}>
                Logout
              </span>
            </>
          ) : (
            <Link to="/login" className="login-link">Login</Link>
          )}
        </div>
      </div>
    </header>
  );
}
export default Header;
